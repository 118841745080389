import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, IconButtonProps, Popover } from '@mui/material';
import { ReactElement, useRef, useState } from 'react';

type renderPopoverContent = (handleClose: () => void) => ReactElement;
interface ActionMenuProps extends IconButtonProps {
  renderPopoverContent: renderPopoverContent;
}

function ActionMenu({ renderPopoverContent, ...anchorProps }: ActionMenuProps): ReactElement {
  const ref = useRef<any>(null);
  const [showActionMenu, setShowActionMenu] = useState(false);

  const toggleActionMenu = () => {
    setShowActionMenu(state => !state);
  };

  const closeActionMenu = () => {
    setShowActionMenu(false);
  };

  return (
    <>
      <IconButton
        onClick={toggleActionMenu}
        ref={ref}
        sx={{ background: 'transparent' }}
        {...anchorProps}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={closeActionMenu}
        open={showActionMenu}
        sx={{ zIndex: 10, '& .MuiPaper-root': { borderRadius: 0 } }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {renderPopoverContent(closeActionMenu)}
      </Popover>
    </>
  );
}

export default ActionMenu;
