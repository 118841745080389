import { Button, CircularProgress } from '@mui/material';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import { StakingContractInfo, useStakingInfo } from 'contexts/StakingInfoContext';
import { useTxStatus } from 'contexts/TxStatusContext';
import STAKE_ABI from 'core/contracts/STAKE_ABI';
import { useWriteTx } from 'hooks/useWriteTx';
import { VaultConfig } from 'types';
import { Address, erc20Abi } from 'viem';
import { useAccount } from 'wagmi';

const stAltConfig = window.appConfig?.vaults?.mach_alpha;
const stAltAddr = stAltConfig?.stakingContracts?.[0];

const MAX_UINT256 = 115792089237316195423570985008687907853269984665640564039457584007913129639935n;

export default function ActionButton({
  allowance,
  amount,
  balance,
  onStaked,
  producerNode,
  promptMaxApproval,
  refetchAllowance,
  selectedStakingContract,
  vaultId,
}: {
  amount: bigint;
  allowance: bigint;
  refetchAllowance: () => void;
  balance: bigint;
  producerNode: Address;
  vaultId: string;
  selectedStakingContract: StakingContractInfo;
  onStaked?: () => void;
  promptMaxApproval: boolean;
}) {
  const vaultConfig = window.appConfig?.vaults?.[vaultId];
  const { refetchContractGlobalVotingStake, refetchNodeStakedBalance } = useStakingInfo();

  const { fetchTokenBalance, tokens } = useStakedTokens();

  const tokenDetails = tokens[selectedStakingContract?.stakedTokenAddr || '0x'];

  if (!allowance || allowance < amount) {
    return (
      <Approve
        amount={amount}
        onApproved={() => {
          refetchAllowance();
        }}
        promptMaxApproval={promptMaxApproval}
        selectedStakingContract={selectedStakingContract}
      />
    );
  }

  return (
    <Stake
      amount={amount}
      balance={balance}
      onStaked={async () => {
        refetchAllowance();
        fetchTokenBalance(stAltAddr, producerNode);
        await Promise.all([
          tokenDetails.refetch(),
          refetchNodeStakedBalance(
            vaultId,
            selectedStakingContract?.stakingContractAddr,
            producerNode
          ),
          refetchContractGlobalVotingStake(selectedStakingContract?.stakingContractAddr),
        ]);
        onStaked?.();
      }}
      producerNode={producerNode}
      selectedStakingContract={selectedStakingContract}
      vaultConfig={vaultConfig}
    />
  );
}

function Approve({
  amount,
  onApproved,
  promptMaxApproval,
  selectedStakingContract,
}: {
  onApproved: () => void;
  amount: bigint;
  selectedStakingContract: StakingContractInfo;
  promptMaxApproval: boolean;
}) {
  const txKey = `approve_${selectedStakingContract?.stakingContractAddr}`;
  const { pendingTxs } = useTxStatus();
  const isApproving = pendingTxs?.has(txKey);
  const { write: approve } = useWriteTx({
    contractAbi: erc20Abi,
    contractAddress: selectedStakingContract?.stakedTokenAddr || '0x',
    functionName: 'approve',
    functionArgs: [
      selectedStakingContract?.stakingContractAddr,
      promptMaxApproval ? MAX_UINT256 : amount,
    ],
    onTxConfirmed: onApproved,
    txKey,
  });
  const stakingStarted = selectedStakingContract?.stakingStarted;

  return (
    <Button
      disabled={!amount || isApproving || !stakingStarted}
      fullWidth
      onClick={() => approve()}
      variant="contained"
    >
      {isApproving && (
        <CircularProgress
          size={15}
          sx={{ '& svg circle': { stroke: theme => theme.colors.schema.primary }, mr: 1, mb: 0.25 }}
        />
      )}
      {isApproving ? 'Approving...' : 'Approve'}
    </Button>
  );
}

function Stake({
  amount,
  balance,
  onStaked,
  producerNode,
  selectedStakingContract,
}: {
  onStaked: () => void;
  amount: bigint;
  balance: bigint;
  producerNode: Address;
  vaultConfig: VaultConfig;
  selectedStakingContract: StakingContractInfo;
}) {
  const { address } = useAccount();

  const txKey = `stake_${selectedStakingContract?.stakingContractAddr}`;
  const { pendingTxs } = useTxStatus();
  const isStaking = pendingTxs?.has(txKey);
  const { write: stake } = useWriteTx({
    contractAbi: STAKE_ABI,
    contractAddress: selectedStakingContract?.stakingContractAddr,
    functionName: 'stake',
    functionArgs: [address, producerNode, amount],
    onTxConfirmed: onStaked,
    txKey,
  });
  const stakingStarted = selectedStakingContract?.stakingStarted;

  return (
    <Button
      disabled={!amount || amount > balance || isStaking || !stakingStarted}
      fullWidth
      onClick={() => stake()}
      type="submit"
      variant="contained"
    >
      {isStaking && (
        <CircularProgress
          size={15}
          sx={{
            '& svg circle': { stroke: theme => theme.colors.functional.text.primary },
            mr: 1,
            mb: 0.25,
          }}
        />
      )}
      {isStaking ? 'Staking...' : 'Stake'}
    </Button>
  );
}
