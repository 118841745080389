import { Badge, Box, BoxProps, Stack, Typography } from '@mui/material';
import { paths } from 'App';
import { useComputedStakingValues } from 'hooks/useComputedStakingValues';
import { useTabs } from 'hooks/useTabs';
import { Link, useLocation } from 'react-router-dom';

const Sidebar: React.FC<BoxProps> = ({ sx, ...rest }) => {
  const location = useLocation();
  const { totalRewardsUsd } = useComputedStakingValues();

  const tabs = useTabs();

  return (
    <Box
      sx={{
        pt: { lg: 3.8 },
        position: 'fixed',
        top: '50px',
        left: '0px',
        bottom: '0px',
        width: { xl: '378px', lg: '240px' },
        display: { lg: 'block', xs: 'none' },
        background: '#D5E9BB',
        zIndex: 2,
        ...sx,
      }}
      {...rest}
    >
      {tabs.map(tab => {
        const shallowestPath = location.pathname?.split('/')?.[1];
        const isRestakingPath = tab.path === paths.Stake && shallowestPath === 'restake';
        const isActiveTab =
          shallowestPath !== '' && (tab.path?.includes(shallowestPath) || isRestakingPath);

        return (
          <Stack
            component={Link}
            flexDirection="row"
            key={tab.path}
            sx={{
              pl: { lg: 2.5 },
              alignItems: 'center',
              height: '60px',
              background: isActiveTab
                ? '#EAF4DC'
                : 'linear-gradient(127.08deg, #EAF4DC -26.35%, #D5E9BB 111.98%)',
              '& > a': {
                color: isActiveTab ? '#6266DB' : '#373741',
              },
              borderBottom: '1px solid #20272308',
              '&& *': { transition: 'all .2s ease-in-out' },
              ':hover > *': {
                transformOrigin: 'left',
                transform: 'scale(1.1)',
              },
            }}
            to={tab.path}
          >
            <tab.icon
              sx={{
                ml: { xl: 2.5, xs: 0 },
                mr: { lg: 2 },
                path: {
                  fill: isActiveTab ? '#6266DB' : '#373741',
                },
                color: isActiveTab ? '#6266DB' : '#373741',
              }}
            />
            {tab.title === 'Rewards' && totalRewardsUsd > 1 ? (
              <Badge
                badgeContent={`$${Math.floor(totalRewardsUsd)}`}
                color="secondary"
                sx={{
                  '& .MuiBadge-badge': {
                    position: 'absolute',
                    right: '-1rem',
                    top: '0.25rem',
                    color: '#fff',
                  },
                }}
                variant="dot"
              >
                <Typography
                  color={isActiveTab ? '#6266DB' : '#373741'}
                  sx={{
                    fontSize: '18px',
                    lineHeight: '28px',
                  }}
                >
                  {tab.title}
                </Typography>
              </Badge>
            ) : (
              <Typography
                color={isActiveTab ? '#6266DB' : '#373741'}
                sx={{
                  fontSize: '18px',
                  lineHeight: '28px',
                }}
              >
                {tab.title}
              </Typography>
            )}
          </Stack>
        );
      })}
    </Box>
  );
};

export default Sidebar;
