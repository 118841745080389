import { Box, Grid, Snackbar, Typography } from '@mui/material';
import IconClose from 'components/icons/IconClose';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useState } from 'react';

export default function NotificationBanner() {
  const { setConvertReAltDialogOpen } = useStakedTokens();
  const [hasDismissed, setHasDismissed] = useLocalStorage('notification_dismissed', false);
  const [isOpen, setIsOpen] = useState(!hasDismissed);

  const handleClose = () => {
    setHasDismissed(true);
    setIsOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      message={
        <>
          <Grid
            alignItems="center"
            container
            flexDirection={'row'}
            justifyContent="space-between"
            px={2}
            spacing={0.5}
          >
            <Grid container item justifyContent="center" lg={11.5} xs={11}>
              <Typography
                color="white"
                onClick={() => {
                  setConvertReAltDialogOpen(true);
                  handleClose();
                }}
                sx={{ cursor: 'pointer', ':hover': { textDecoration: 'underline' } }}
              >
                Save gas fees with auto-compound. Convert your stALT to reALT now!
              </Typography>
            </Grid>
            <Grid item lg={0.5} xs={1}>
              <Box
                onClick={handleClose}
                sx={{
                  right: '1rem',
                  cursor: 'pointer',
                  '& svg': { width: '1.4rem', height: '1.4rem' },
                }}
              >
                <IconClose />
              </Box>
            </Grid>
          </Grid>
        </>
      }
      onClose={() => {}}
      open={isOpen}
      sx={{
        '&&': {
          top: { xs: 140, lg: 80 },
          left: { xs: 0, lg: 240, xl: 378 },
          mr: { xs: 4, sm: 0 },
          width: { xs: '100vw', lg: 'calc(100vw - 240px)', xl: 'calc(100vw - 378px)' },
          display: 'flex',
          justifyContent: 'center',
        },
        '& .MuiPaper-root': {
          width: '100vw',
          bgcolor: '#62AA55',
          display: 'block',
          borderRadius: 0,
        },
      }}
      // autoHideDuration={5000}
    />
  );
}
