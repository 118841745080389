import { LoadingButton } from '@mui/lab';
import { ButtonProps, Stack, Typography } from '@mui/material';
import ConfirmationDialog from 'components/ConfirmationDialog';
import IconInfo from 'components/icons/IconInfo';
import IconWarning from 'components/icons/IconWarning';
import MaxButton from 'components/MaxButton';
import MaximizableAmount from 'components/MaximizableAmount';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import { useStakingInfo } from 'contexts/StakingInfoContext';
import { useTxStatus } from 'contexts/TxStatusContext';
import STAKE_ABI from 'core/contracts/STAKE_ABI';
import { commify, formatWithPrecision, msToDaysHrsMinsSeconds } from 'core/utils';
import { useAmount } from 'hooks/useAmount';
import { useComputedStakingValues } from 'hooks/useComputedStakingValues';
import { useIsWrongNetwork } from 'hooks/useIsWrongNetwork';
import { useWriteTx } from 'hooks/useWriteTx';
import { useEffect, useState } from 'react';
import { VaultConfig } from 'types';
import { Address, formatUnits } from 'viem';

export default function CooldownButton({
  onConfirmed,
  operatorAddr,
  stakingContractAddr,
  sx,
  vaultConfig,
  ...rest
}: ButtonProps & {
  onConfirmed?: () => void;
  operatorAddr: Address;
  stakingContractAddr: Address;
  vaultConfig: VaultConfig;
}) {
  const { refetchNodeCooldownInfo, refetchNodeStakedBalance, stakingInfo } = useStakingInfo();
  const isWrongNetwork = useIsWrongNetwork();

  const { amountBN, amountRaw, setAmountRaw } = useAmount(18);
  const [showCooldownDialog, setShowCooldownDialog] = useState(false);
  const { nodeBalances } = useComputedStakingValues();
  const { fetchTokenBalance } = useStakedTokens();
  const stakingContractInfo = stakingInfo?.[vaultConfig?.id]?.[stakingContractAddr];
  const balances = nodeBalances?.[vaultConfig?.id]?.[stakingContractAddr]?.[operatorAddr];
  const nodeStakedBalance = balances?.accruingRewards;
  const tokenSymbol = stakingContractInfo?.stakedTokenSymbol;
  const cooldownSeconds = stakingContractInfo?.cooldownSeconds;
  const timeArr = msToDaysHrsMinsSeconds((cooldownSeconds || 0) * 1000);
  const timeUnits = ['day', 'hour', 'minute', 'second'];
  const cooldownString =
    timeArr.reduce(
      (result, cur, index) =>
        cur > 0
          ? result + ' ' + String(cur) + ' ' + timeUnits[index] + `${cur > 1 ? 's' : ''}`
          : result,
      ''
    ) || '0s';
  const txKey = `cooldown_${stakingContractAddr}`; // assume 1 operator only.
  const { pendingTxs } = useTxStatus();
  const isLoading = pendingTxs?.has(txKey);
  const { write: beginCooldown } = useWriteTx({
    contractAbi: STAKE_ABI,
    contractAddress: stakingContractAddr,
    functionName: 'cooldownToUnstake',
    functionArgs: [operatorAddr, amountBN],
    onTxConfirmed: () => {
      onConfirmed?.();
      setShowCooldownDialog(false);
      fetchTokenBalance(stakingContractAddr, operatorAddr);
      refetchNodeStakedBalance(vaultConfig?.id, stakingContractAddr, operatorAddr);
      refetchNodeCooldownInfo(vaultConfig?.id, stakingContractAddr, operatorAddr);
    },
    txKey,
  });

  useEffect(() => {
    setAmountRaw(formatUnits(nodeStakedBalance || 0n, 18));
  }, [nodeStakedBalance, setAmountRaw]);

  return (
    <>
      <ConfirmationDialog
        confirmButtonProps={{
          disabled: amountBN <= 0 || amountBN > (nodeStakedBalance || 0n),
        }}
        confirmButtonText={'Cooldown'}
        handleClose={() => {
          setShowCooldownDialog(false);
        }}
        isLoading={isLoading}
        onConfirm={beginCooldown}
        open={showCooldownDialog}
        title="Cooldown amount"
      >
        <MaximizableAmount
          fullWidth
          setAmountRaw={setAmountRaw}
          tokenSymbol={tokenSymbol}
          value={amountRaw}
        />
        <Stack direction="row" justifyContent="space-between" mt={{ md: 1.25, xs: 1 }}>
          <Typography variant="subtitle1">
            TOTAL BALANCE:&nbsp;
            <Typography component="span" variant="subtitle1">
              {commify(formatWithPrecision(nodeStakedBalance || 0n)) || '-'}
            </Typography>
            <Typography component="span" variant="subtitle1">
              &nbsp;{tokenSymbol}
            </Typography>
          </Typography>
          <MaxButton
            onClick={() => {
              setAmountRaw(formatUnits(nodeStakedBalance || 0n, 18));
            }}
          />
        </Stack>

        <Stack alignItems="center" direction="row" gap={1} mt={{ md: 3, xs: 1.5 }}>
          <IconInfo sx={{ width: 20 }} />
          <Typography fontSize={14}>
            Tokens have to enter a {cooldownString} cooldown period before they are unstaked.
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row" gap={1} mt={{ md: 3, xs: 1.5 }}>
          <IconInfo sx={{ width: 20 }} />
          <Typography fontSize={14}>Rewards will not accrue on this token amount.</Typography>
        </Stack>
        {Boolean(balances?.inCooldown + balances?.withdrawable) && (
          <Typography
            mt={{ md: 1.25, xs: 1 }}
            sx={{ bgcolor: '#F5F5DA', p: 2, mt: 2, fontSize: 12 }}
            variant="subtitle1"
          >
            <IconWarning sx={{ stroke: 'red', fill: 'none', width: 20, height: 20, mb: -0.8 }} />{' '}
            You have pending/finished cooldowns on{' '}
            <Typography component="span" sx={{ fontSize: 12, textDecoration: 'underline' }}>
              {formatWithPrecision(balances?.inCooldown + balances?.withdrawable)} {tokenSymbol}
            </Typography>
            . Proceeding will reset the cooldown period on this amount.
          </Typography>
        )}
      </ConfirmationDialog>
      <LoadingButton
        disabled={!nodeStakedBalance || isWrongNetwork}
        loading={isLoading}
        onClick={() => {
          setShowCooldownDialog(true);
        }}
        sx={sx}
        variant="outlined"
        {...rest}
      >
        Unstake
      </LoadingButton>
    </>
  );
}
