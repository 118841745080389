import { Box, Grid, GridProps, Link as MuiLink, Stack, Typography } from '@mui/material';
import IconExtLink from 'components/icons/IconExtLink';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const v2VaultsConfigs = window.appConfig?.v2Vaults;
const vaultsConfigs = window.appConfig?.vaults;

const RewardDetailsCardMobile: FC<GridProps & { vaultId: string }> = ({ sx, vaultId, ...rest }) => {
  const vaultConfig = v2VaultsConfigs?.[Number(vaultId)] || vaultsConfigs?.[vaultId];

  return (
    <Grid
      container
      sx={{
        background: 'linear-gradient(154.71deg, #FFFFFF 0%, #F7F7F7 100%)',
        '&>*': {
          borderBottom: '1px solid #20272308',
          p: 3,
        },
        ...sx,
      }}
      {...rest}
    >
      <Grid item xs={12}>
        <Stack alignItems="center" direction="row" gap={1}>
          <Box
            component="img"
            src={vaultConfig?.iconUrl}
            sx={{
              width: 30,
            }}
          />
          <Typography variant="h5">{vaultConfig?.name}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">APY</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography textAlign="right" variant="h6">
          {vaultConfig?.isLive ? '' : 'LaunchPool'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">REWARDS</Typography>
      </Grid>
      <Grid item xs={6}>
        {vaultConfig?.rewardsUrl ? (
          <MuiLink
            component={Link}
            rel="noopener noreferrer"
            sx={{
              fontWeight: 600,
              '&:hover': {
                textDecoration: 'underline',
              },
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
            target="_blank"
            to={vaultConfig?.rewardsUrl}
          >
            <Typography textAlign="right">
              {v2VaultsConfigs?.[Number(vaultId)]?.rewardsUrl}
            </Typography>
            <IconExtLink sx={{ position: 'static' }} />
          </MuiLink>
        ) : (
          <Typography textAlign="right" variant="h6">
            Coming Soon
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default RewardDetailsCardMobile;
