import { Box, BoxProps, useTheme } from '@mui/material';
import { FC } from 'react';

export const CardContainer: FC<BoxProps> = ({ children, sx }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.colors.functional.container.primary,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(3.75),
        paddingBottom: theme.spacing(3.75),
        [theme.breakpoints.up('md')]: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          paddingTop: theme.spacing(3.75),
          paddingBottom: theme.spacing(3.75),
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
