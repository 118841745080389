import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconClose({ ...props }: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" id="页面-1" stroke="none" strokeWidth="1">
        <g id="Multi-stake3" transform="translate(-820, -171)">
          <g id="编组-3备份-4" transform="translate(820, 171)">
            <rect height="24" id="矩形" width="24" x="0" y="0"></rect>
            <polygon
              fill="currentColor"
              fillRule="nonzero"
              id="路径"
              points="19.0433325 6.08036951 13.0810897 12.0426123 19.0433325 18.0062954 18.0062954 19.0433325 12.0440526 13.0796493 6.08036951 19.0433325 5.04333248 18.0062954 11.0070156 12.0426123 5.04333248 6.08036951 6.08036951 5.04333248 12.0440526 11.0055753 18.0062954 5.04333248"
            ></polygon>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
