import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Footer from 'components/layout/Footer';
import Header from 'components/layout/Header';
import MobileNavbar from 'components/layout/MobileNavbar';
import Sidebar from 'components/layout/Sidebar';
import NotificationBanner from 'components/NotificationBanner';
import React, { ReactNode } from 'react';

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box>
      <NotificationBanner />
      <Header />
      <MobileNavbar />
      <Stack
        flexDirection="row"
        minHeight={{ xs: 'calc(100vh - 160px)', lg: 'calc(100vh - 80px)' }}
        mt={{ xs: '140px', lg: '80px' }}
      >
        <Sidebar />
        <Stack
          sx={{
            minHeight: 'calc(100vh - 80px)',
            ml: { xl: '378px', lg: '240px', xs: 0 },
            width: '100%',
            position: 'relative',
          }}
        >
          <Stack
            flex={1}
            sx={{ background: 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)' }}
          >
            {children}
          </Stack>
          <Footer />
        </Stack>
      </Stack>
    </Box>
  );
};

export default Layout;
