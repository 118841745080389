/* eslint-disable prettier/prettier */
// import { solidityKeccak256 } from 'ethers/lib/utils';

// const customRevertErrors: Record<string, string> = {
//   'NotDefaultAdmin()': 'You are not the default admin of this contract.',
//   'ZeroAddress()': 'Zero address not allowed.',
//   'InvalidStartIndex()': 'Start index is invalid.',
// };
// const customRevertErrorMap: Record<string, string> = Object.keys(customRevertErrors)?.reduce(
//   (acc, key) => ({
//     ...acc,
//     [solidityKeccak256(['string'], [key])?.substring(0, 10)]: customRevertErrors[key],
//   }),
//   {}
// );

const ERROR_MESSAGES: Record<string, string> = {
  'User rejected': 'User rejected the request.',
  INVALID_BALANCE_ON_COOLDOWN: 'You do not have enough staked tokens to cooldown.',
  ZeroAddress: 'Zero address not allowed.',
  ZeroShare: 'You do not have any reALT to delegate.',
  AddressInsufficientBalance: 'Insufficient balance.',
  OptInPeriodNotStarted: 'The Opt-in period has not started yet.',
  OptInPeriodEnded: 'The Opt-in period has ended.',
  ERC4626ExceededMaxWithdraw: 'The maximum withdrawable amount has been exceeded.',
  ERC4626ExceededMaxRedeem: 'The maximum redeemable amount has been exceeded.',
  ERC4626ExceededMaxDeposit: 'The maximum deposit amount has been exceeded.',
  ERC20InsufficientBalance: 'Insufficient ERC20 token balance.',
  ERC20InsufficientAllowance: 'Insufficient ERC20 token allowance.',
  ERC1155InsufficientBalance: 'Insufficient stALT balance',
};

console.debug('Revert error map: ', ERROR_MESSAGES);

export default function errorParser(error: any) {
  let nestedError = error;

  if (error?.data?.data) {
    nestedError = error.data;
  }

  const regexTest = new RegExp(Object.keys(ERROR_MESSAGES).join('|'), 'i').exec(
    nestedError?.message
  );

  console.debug('error message regex match: ', regexTest?.[0]);

  const mappedError =
    ERROR_MESSAGES[regexTest?.[0] as string];
    // || customRevertErrorMap[nestedError?.data as string];

  return mappedError || error?.message;
}
