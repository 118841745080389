import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import {
  WaitForTransactionReceiptErrorType,
  WriteContractErrorType,
  WriteContractReturnType,
} from '@wagmi/core';
import { AbiStateMutability, ExtractAbiFunctionNames } from 'abitype';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useTxStatus } from 'contexts/TxStatusContext';
import { useWagmi } from 'contexts/WagmiContext';
import errorParser from 'core/errorParser';
import { Abi, Address, Client } from 'viem';
import { waitForTransactionReceipt } from 'viem/actions';
import { useClient, useWriteContract } from 'wagmi';

const waitWithRetryForTxReceipt = async (
  client: Client,
  txHash: `0x${string}`,
  retries = 3,
  timeBetweenRetries = 1000
) => {
  try {
    await waitForTransactionReceipt(client, { hash: txHash });
  } catch (err) {
    if (retries > 0) {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          waitWithRetryForTxReceipt(client, txHash, retries - 1, timeBetweenRetries)
            ?.then(resolve)
            ?.catch(err => {
              reject(err);
            });
        }, timeBetweenRetries);
      });
    }

    if (retries <= 0) {
      return Promise.reject(err);
    }
  }
};

// const gasOverrideFnMap = window.appConfig?.ui?.gasLimitOverrides || {};

interface UseWriteTxArgs {
  txKey: string;
  contractAbi: Abi;
  contractAddress: Address;
  functionArgs?: unknown[];
  functionName: ExtractAbiFunctionNames<Abi, AbiStateMutability>;
  onError?: (error: WriteContractErrorType | WaitForTransactionReceiptErrorType) => void;
  onTxConfirmed?: (txReceipt: WriteContractReturnType) => void;
}

const getTxLink = (txHash: string, url: string, name: string) => ({
  url: `${url}/tx/${txHash}`,
  label: `View tx on ${name}`,
});

export const useWriteTx = (props: UseWriteTxArgs) => {
  const {
    contractAbi,
    contractAddress,
    functionArgs,
    functionName,
    onError,
    onTxConfirmed,
    txKey,
  } = props;

  const { addAlert, removeAlert } = useAlerts();
  const addRecentTransaction = useAddRecentTransaction();

  const { addPendingTx, removePendingTx } = useTxStatus();
  const { chains } = useWagmi();
  const client = useClient();
  const {
    data: txHash,
    isSuccess,
    writeContract,
  } = useWriteContract({
    mutation: {
      onSuccess: async txHash => {
        addRecentTransaction({ hash: txHash, description: functionName });
        const pendingTxKey = addAlert({
          title: `${functionName} transaction pending`,
          desc: `Tx hash: ${txHash}`,
          severity: ALERT_SEVERITY.PENDING,
          link: getTxLink(
            txHash,
            chains?.[0]?.blockExplorers?.default.url || '',
            chains?.[0]?.blockExplorers?.default?.name || ''
          ),
          timeout: Infinity,
        });

        if (client) {
          await waitWithRetryForTxReceipt(client, txHash);
          removePendingTx(txKey);
          removeAlert(pendingTxKey);
          addAlert({
            title: `${functionName} transaction confirmed`,
            desc: `Tx hash: ${txHash}`,
            link: getTxLink(
              txHash,
              chains?.[0]?.blockExplorers?.default.url || '',
              chains?.[0]?.blockExplorers?.default?.name || ''
            ),
            severity: ALERT_SEVERITY.SUCCESS,
          });
          onTxConfirmed?.(txHash);
        } else {
          console.error('no client configured. Client: ', client);
        }
      },
      onError: err => {
        console.error(`${functionName} transaction failed`, err);
        removePendingTx(txKey);
        addAlert({
          title: `${functionName} transaction failed`,
          severity: ALERT_SEVERITY.ERROR,
          desc: errorParser(err),
          link: txHash
            ? getTxLink(
                txHash,
                chains?.[0]?.blockExplorers?.default.url || '',
                chains?.[0]?.blockExplorers?.default?.name || ''
              )
            : undefined,
        });
        onError?.(err);
      },
    },
  });

  const write = () => {
    console.debug(`[${functionName}] function args: `, functionArgs);
    console.debug(`[${functionName}] props: `, props);
    addPendingTx(txKey);
    writeContract({
      abi: contractAbi,
      address: contractAddress,
      functionName,
      args: functionArgs,
    });
  };

  return { write, isSuccess };
};
