import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconVault({ ...props }: SvgIconProps) {
  return (
    <SvgIcon fill="none" height="26" viewBox="0 0 26 26" width="26" {...props}>
      <path
        d="M12.7096 18.0086V16.9042C11.0474 16.7562 10.0227 15.7202 9.99992 14.1832H11.0018C11.036 15.2192 11.6735 15.9479 12.7096 16.0959V13.0788L12.4136 13.0105C11.1954 12.7145 10.2618 11.9972 10.2618 10.8018C10.2618 9.61771 11.2751 8.72966 12.7096 8.60443V7.50006H13.5407V8.6272C14.9183 8.79797 15.7722 9.68602 15.8064 11.0067H14.8045C14.7817 10.1984 14.3149 9.60632 13.5407 9.43554V12.3046L13.8823 12.3843C15.1574 12.6917 15.9999 13.3293 15.9999 14.5361C15.9999 15.7316 15.0436 16.7335 13.5407 16.8928V18.0086H12.7096ZM11.2751 10.8018C11.2751 11.4166 11.6622 11.872 12.6754 12.1111L12.7096 12.1225V9.41277C11.8557 9.51524 11.2751 9.99342 11.2751 10.8018ZM13.5407 16.0845C14.4402 15.9479 14.9753 15.3558 14.9753 14.6044C14.9753 13.8872 14.4174 13.5114 13.5407 13.2724V16.0845Z"
        fill="#202723"
      />
      <circle
        cx="21.5369"
        cy="21.5312"
        fill="#202723"
        r="1.39211"
        transform="rotate(45 21.5369 21.5312)"
      />
      <circle
        cx="4.47441"
        cy="21.5312"
        fill="#202723"
        r="1.39211"
        transform="rotate(45 4.47441 21.5312)"
      />
      <circle
        cx="21.5369"
        cy="4.46875"
        fill="#202723"
        r="1.39211"
        transform="rotate(45 21.5369 4.46875)"
      />
      <circle
        cx="4.47441"
        cy="4.46875"
        fill="#202723"
        r="1.39211"
        transform="rotate(45 4.47441 4.46875)"
      />
      <path
        clipRule="evenodd"
        d="M13.3536 2.14646L13 1.79291L12.6464 2.14646L7.75294 7.03997L4.50003 3.78706L3.79292 4.49417L7.04583 7.74708L2.14645 12.6465L1.79289 13L2.14645 13.3536L7.04575 18.2529L3.79292 21.5057L4.50003 22.2128L7.75286 18.96L12.6464 23.8536L13 24.2071L13.3536 23.8536L18.2531 18.954L21.5119 22.2128L22.219 21.5057L18.9602 18.2469L23.8536 13.3536L24.2071 13L23.8536 12.6465L18.9601 7.75305L22.219 4.49416L21.5119 3.78705L18.253 7.04594L13.3536 2.14646ZM13 22.7929L3.20711 13L13 3.20712L22.7929 13L13 22.7929Z"
        fill="#202723"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
