import { Box, Skeleton, Stack, StackProps, Typography } from '@mui/material';
import SwapStkAltDialog from 'components/SwapStkAltDialog';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import { useStakingInfo } from 'contexts/StakingInfoContext';
import { Dispatch, FC, SetStateAction } from 'react';

const stkAltAddr = window.appConfig?.vaults?.mach_alpha?.stakingContracts?.[0];
const vault = window.appConfig?.erc4626Vault;

const StkALTDisplay = () => {
  const { convertReAltDialogOpen, setConvertReAltDialogOpen, tokens } = useStakedTokens();
  const { stakingInfo } = useStakingInfo();
  const stkAltOperatorAddr = stakingInfo?.mach_alpha?.[stkAltAddr]?.operatorAddresses?.[0];

  if (!stkAltOperatorAddr || !tokens?.[stkAltAddr]?.symbol || !tokens?.[vault]?.symbol) {
    return (
      <Stack alignItems="center" direction="row" gap={2} justifyContent="center">
        <Skeleton sx={{ fontSize: '1rem', width: '1rem', height: '1rem' }} variant="circular" />
        <Skeleton sx={{ fontSize: '1rem', width: '2rem' }} variant="text" />
        <Skeleton sx={{ fontSize: '1rem', width: '1rem', height: '1rem' }} variant="circular" />
        <Skeleton sx={{ fontSize: '1rem', width: '2rem' }} variant="text" />
      </Stack>
    );
  }

  return (
    <>
      <SwapStkAltDialog
        handleClose={() => {
          setConvertReAltDialogOpen(false);
        }}
        open={convertReAltDialogOpen}
      />
      <StkALTDisplayDesktop setIsSwapDialogOpen={setConvertReAltDialogOpen} />
      <StkALTDisplayMobile setIsSwapDialogOpen={setConvertReAltDialogOpen} />
    </>
  );
};

const StkALTDisplayDesktop: FC<
  { setIsSwapDialogOpen: Dispatch<SetStateAction<boolean>> } & StackProps
> = ({ setIsSwapDialogOpen, sx, ...rest }) => {
  const { tokens } = useStakedTokens();

  return (
    <Stack
      alignItems="center"
      className="hover-grow"
      direction="row"
      justifyContent="center"
      onClick={() => {
        setIsSwapDialogOpen(true);
      }}
      sx={{
        display: { xs: 'none', sm: 'flex' },
        position: 'relative',
        px: 0.5,
        '&>div': {
          p: 1,
        },
        cursor: 'pointer',
        background: 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)',
        height: '100%',
        ':hover': {
          transform: 'scale(1.02)',
          // background: `linear-gradient(127.48deg, ${darken('#EAF4DC', 0.02)} 0%, ${darken(
          //   '#D5E9BB',
          //   0.02
          // )} 100%)`,
        },
        ...sx,
      }}
      {...rest}
    >
      <Typography
        alignItems="center"
        columnGap={0.5}
        component={Stack}
        direction="row"
        justifyContent="center"
      >
        <Box
          component="img"
          src="/icons/iconAltLayer.svg"
          sx={{
            width: 20,
            mr: 0.5,
          }}
        />
        {tokens?.[stkAltAddr]?.formattedBalance} {tokens?.[stkAltAddr]?.symbol}
      </Typography>
      <Typography
        alignItems="center"
        columnGap={0.5}
        component={Stack}
        direction="row"
        justifyContent="center"
      >
        <Box
          component="img"
          src="/icons/iconReAlt.svg"
          sx={{
            width: 20,
          }}
        />
        {tokens?.[vault]?.formattedBalance} {tokens?.[vault]?.symbol}
      </Typography>
      <Box
        component="img"
        src="/icons/iconSwap.svg"
        sx={{
          width: 20,
          m: 0.75,
          borderRadius: '0.5rem',
        }}
      />
    </Stack>
  );
};

const StkALTDisplayMobile: FC<
  { setIsSwapDialogOpen: Dispatch<SetStateAction<boolean>> } & StackProps
> = ({ setIsSwapDialogOpen, sx, ...rest }) => {
  return (
    <Stack
      alignItems="center"
      className="hover-grow"
      direction="row"
      justifyContent="center"
      onClick={() => {
        setIsSwapDialogOpen(true);
      }}
      sx={{
        display: { xs: 'flex', sm: 'none' },
        position: 'relative',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        background: 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)',
        px: 0.5,
        '&>div': {
          p: 1,
        },
        cursor: 'pointer',
        ':hover': {
          transform: 'scale(1.02)',
          // background: `linear-gradient(127.48deg, ${darken('#EAF4DC', 0.02)} 0%, ${darken(
          //   '#D5E9BB',
          //   0.02
          // )} 100%)`,
        },
        ...sx,
      }}
      {...rest}
    >
      <Typography
        alignItems="center"
        columnGap={0.5}
        component={Stack}
        direction="row"
        justifyContent="center"
      >
        <Box
          component="img"
          src="/icons/iconAltLayer.png"
          sx={{
            width: 20,
          }}
        />
      </Typography>
      <Box
        component="img"
        src="/icons/iconSwap.svg"
        sx={{
          width: 28,
          m: 0.75,
          borderRadius: '0.5rem',
          color: 'white',
        }}
      />
      <Typography
        alignItems="center"
        columnGap={0.5}
        component={Stack}
        direction="row"
        justifyContent="center"
      >
        <Box
          component="img"
          src="/icons/iconReAlt.svg"
          sx={{
            width: 20,
          }}
        />
      </Typography>
    </Stack>
  );
};

export default StkALTDisplay;
