import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconRestake({ ...props }: SvgIconProps) {
  return (
    <SvgIcon fill="none" height="26" viewBox="0 0 26 26" width="26" {...props}>
      <path
        clipRule="evenodd"
        d="M13 4C13.8285 4 14.5 3.32843 14.5 2.5C14.5 1.67157 13.8285 1 13 1C12.3106 1 11.7298 1.46515 11.5543 2.09875C7.53094 2.65281 4.23491 5.48632 3.01137 9.25407L3.9538 9.59065C5.04689 6.18922 8.00517 3.62387 11.6244 3.09891C11.8556 3.62926 12.3845 4 13 4ZM9.18393 22.285C5.70266 20.9264 3.12448 17.7635 2.59874 13.9457C1.96514 13.7703 1.5 13.1894 1.5 12.5C1.5 11.6716 2.17157 11 3 11C3.82843 11 4.5 11.6716 4.5 12.5C4.5 13.1155 4.12926 13.6445 3.59889 13.8757C4.0941 17.2897 6.40494 20.1157 9.52052 21.3426L9.50002 21.4L9.18393 22.285ZM13 21C13.6156 21 14.1445 21.3708 14.3757 21.9012C17.7898 21.4059 20.6157 19.0951 21.8426 15.9795L21.9 16L22.7851 16.3161C21.4264 19.7974 18.2635 22.3756 14.4457 22.9013C14.2702 23.5349 13.6895 24 13 24C12.1716 24 11.5 23.3284 11.5 22.5C11.5 21.6716 12.1716 21 13 21ZM22.4011 11.1243C21.8708 11.3555 21.5 11.8845 21.5 12.5C21.5 13.3284 22.1716 14 23 14C23.8284 14 24.5 13.3284 24.5 12.5C24.5 11.8106 24.0349 11.2298 23.4013 11.0543C22.8472 7.03093 20.0137 3.7349 16.246 2.51137L15.9094 3.4538C19.3108 4.54688 21.8762 7.50514 22.4011 11.1243ZM12.7097 16.4042V17.5085H13.5408V16.3928C15.0436 16.2334 16 15.2315 16 14.0361C16 12.8292 15.1575 12.1917 13.8824 11.8843L13.5408 11.8046V8.93548C14.315 9.10626 14.7818 9.69829 14.8046 10.5066H15.8065C15.7723 9.18596 14.9184 8.29791 13.5408 8.12713V7H12.7097V8.10436C11.2751 8.2296 10.2619 9.11765 10.2619 10.3017C10.2619 11.4972 11.1954 12.2144 12.4137 12.5104L12.7097 12.5787V15.5958C11.6736 15.4478 11.0361 14.7192 11.0019 13.6831H10C10.0228 15.2201 11.0474 16.2562 12.7097 16.4042ZM12.6755 11.611C11.6622 11.3719 11.2751 10.9165 11.2751 10.3017C11.2751 9.49336 11.8558 9.01518 12.7097 8.91271V11.6224L12.6755 11.611ZM14.9753 14.1044C14.9753 14.8558 14.4402 15.4478 13.5408 15.5844V12.7723C14.4175 13.0114 14.9753 13.3871 14.9753 14.1044Z"
        fill="#202723"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
