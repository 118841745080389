import { lighten } from '@mui/material';

import { GetComponentStyleFunc } from './types';

export const muiButton: GetComponentStyleFunc<'MuiButton'> = theme => {
  return {
    defaultProps: {
      size: 'large',
      variant: 'contained',
    },
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          color: 'white',
          background: '#202723',
          svg: {
            path: {
              fill: 'white',
            },
            circle: {
              stroke: 'white',
            },
          },
          '&:hover': {
            background: lighten('#303731', 0.1),
          },
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          color: '#202723',
          border: '1px solid #202723',
          svg: {
            path: {
              fill: '#202723',
            },
            circle: {
              stroke: '#202723',
            },
          },
          '&:hover': {
            border: '1px solid #202723',
            background: 'rgba(83, 81, 155, 0.06)',
          },
        },
      },
      {
        props: { variant: 'text' },
        style: {
          color: '#202723',
          background: 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)',
          svg: {
            height: '16px',
            width: 'auto',
            path: {
              fill: 'white',
            },
            circle: {
              stroke: 'white',
            },
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        fontSize: '16px',
        fontWeight: 900,
        borderRadius: 0,
        minWidth: '7rem',
        '&.MuiLoadingButton-root': {
          marginRight: 0,
        },
        '& .MuiLoadingButton-loadingIndicator circle': {
          stroke: theme.colors.functional.text.primary,
        },
        textTransform: 'none',
      },
      containedSizeLarge: {
        height: '48px',
      },
      outlinedSizeLarge: {
        height: '48px',
      },
    },
  };
};

export const muiButtonBase: GetComponentStyleFunc<'MuiButtonBase'> = () => {
  return {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          opacity: 0.7,
        },
      },
    },
  };
};
