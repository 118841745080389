import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconArrowDownFilled({ ...props }: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 38 38" {...props}>
      <path
        d="M38 19C38 29.4934 29.4934 38 19 38C8.50659 38 0 29.4934 0 19C0 8.50659 8.50659 0 19 0C29.4934 0 38 8.50659 38 19Z"
        fill="currentColor"
      />
      <path
        d="M18.9471 13.3452L18.9471 24.5411M18.9471 24.5411L24.545 18.9431M18.9471 24.5411L13.3491 18.9431"
        stroke="url(#paint0_linear_32_1396)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_32_1396"
          x1="24.545"
          x2="11.068"
          y1="13.3452"
          y2="19.7141"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F7F7F7" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
