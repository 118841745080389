import { Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import { useStakingInfo } from 'contexts/StakingInfoContext';
import { NodeBalance } from 'hooks/useComputedStakingValues';
import { Fragment, PropsWithChildren, ReactNode } from 'react';
import { Address } from 'viem';

import PortfolioDetailsCardMobile from './PortfolioDetailsCardMobile';
import { Erc20VaultsTableRow, VaultsTableRow, VaultsV2TableRow } from './VaultsTableRow';

const vaultsConfigs = window.appConfig?.vaults || {};
const v2VaultsConfigs = window.appConfig?.v2Vaults;
const reAltAddr = window.appConfig?.erc4626Vault;

export function VaultsPortfolioTable({
  additionalRows,
  v1VaultBalances,
  v2VaultIds,
}: PropsWithChildren & {
  additionalRows?: ReactNode;
  v2VaultIds?: number[];
  v1VaultBalances: [string, Record<Address, NodeBalance>][];
}) {
  const { stakingInfo } = useStakingInfo();
  const { tokens } = useStakedTokens();

  const hasDisplayableBalances = Boolean(
    v2VaultIds?.length ||
      additionalRows ||
      v1VaultBalances?.reduce(
        (sum, [, balanceByContract]) =>
          sum +
          Object.values(balanceByContract || {})?.reduce(
            (contractSum, balances) =>
              contractSum +
              (balances?.accruingRewards || 0n) +
              (balances?.inCooldown || 0n) +
              (balances?.withdrawable || 0n),
            0n
          ),
        0n
      )
  );
  const v1VaultIds = v1VaultBalances?.map(([vaultId]) => vaultId);

  return (
    <>
      <Table
        sx={{
          display: { xs: 'none', sm: 'table' },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>Vault</TableCell>
            <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>APY</TableCell>
            <TableCell sx={{ display: { xs: 'table-cell', md: 'none' } }}>Vault [APY]</TableCell>
            <TableCell>Balance</TableCell>
            <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>Status</TableCell>
            {v2VaultIds?.find(vaultId => Boolean(v2VaultsConfigs?.[vaultId]?.erc20VaultAddr)) && (
              <TableCell sx={{ width: { xs: 150, md: 220 } }} />
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!hasDisplayableBalances ? (
            <TableRow>
              <TableCell colSpan={99}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="center"
                  sx={{ fontWeight: 900 }}
                >
                  You do not have any staked tokens
                </Stack>
              </TableCell>
            </TableRow>
          ) : (
            <>
              {v1VaultBalances?.map(([vaultId, vaultBals]) => (
                <Fragment key={vaultId}>
                  {Object.keys(vaultBals)?.map((contractAddr, contractIndex) => {
                    return (
                      <VaultsTableRow
                        balance={vaultBals[contractAddr as Address]}
                        contractIndex={contractIndex}
                        key={contractAddr}
                        operatorAddr={
                          stakingInfo?.[vaultId]?.[contractAddr as Address]?.operatorAddresses?.[0]
                        } // assume only 1 operator per contract
                        stakingContractAddr={contractAddr as Address}
                        vaultConfig={vaultsConfigs?.[vaultId]}
                      />
                    );
                  })}
                </Fragment>
              ))}
              {v2VaultIds?.map(vaultId => (
                <Fragment key={vaultId}>
                  {Boolean(tokens?.[reAltAddr]?.balance) && (
                    <VaultsV2TableRow vaultConfig={v2VaultsConfigs?.[vaultId]} />
                  )}
                  {v2VaultsConfigs?.[vaultId]?.erc20VaultAddr && (
                    <Erc20VaultsTableRow vaultConfig={v2VaultsConfigs?.[vaultId]} />
                  )}
                </Fragment>
              ))}
              {additionalRows}
            </>
          )}
        </TableBody>
      </Table>
      {!hasDisplayableBalances ? (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          mt={6}
          px={3}
          sx={{ display: { xs: 'flex', sm: 'none' } }}
        >
          You do not have any staked tokens
        </Stack>
      ) : (
        [...v1VaultIds, ...(v2VaultIds || [])].map(vaultId => (
          <PortfolioDetailsCardMobile key={vaultId} vaultId={String(vaultId)} />
        ))
      )}
    </>
  );
}
