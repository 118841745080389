import { LoadingButton } from '@mui/lab';
import { Box, ButtonProps, FormControl, Stack, Typography } from '@mui/material';
import ConfirmationDialog from 'components/ConfirmationDialog';
import IconInfo from 'components/icons/IconInfo';
import IconWarning from 'components/icons/IconWarning';
import MaxButton from 'components/MaxButton';
import MaximizableAmount from 'components/MaximizableAmount';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import { useTxStatus } from 'contexts/TxStatusContext';
import ERC20_VAULT_ABI from 'core/contracts/ERC20_VAULT_ABI';
import { commify, formatWithPrecision, msToDaysHrsMinsSeconds } from 'core/utils';
import { useAmount } from 'hooks/useAmount';
import { useIsWrongNetwork } from 'hooks/useIsWrongNetwork';
import { useWriteTx } from 'hooks/useWriteTx';
import { useState } from 'react';
import { V2VaultConfig } from 'types';
import { formatUnits } from 'viem';
import { useAccount } from 'wagmi';

const erc20Info = window.appConfig?.erc20Info;

export default function UnstakeERC20VaultButton({
  actionName = 'Unstake',
  cooldownInfo,
  cooldownSeconds,
  onConfirmed,
  sx,
  vaultConfig,
  ...rest
}: ButtonProps & {
  actionName?: 'Unstake' | 'Redeem';
  onConfirmed?: () => void;
  vaultConfig: V2VaultConfig;
  cooldownSeconds?: number;
  cooldownInfo?: [bigint, number];
}) {
  const [cooldownAmount] = cooldownInfo || [];

  const stakedTokenAddr = vaultConfig?.stakedTokenAddr || '0x';
  const erc20VaultAddr = vaultConfig?.erc20VaultAddr || '0x';
  const { fetchTokenBalance, tokens } = useStakedTokens();
  const { decimals, formattedBalance, symbol } = tokens?.[stakedTokenAddr] || {};
  const { balance: stakedBalance, formattedBalance: stakedFormattedBalance } =
    tokens?.[erc20VaultAddr] || {};
  const {
    amountBN: withdrawAmtBN,
    amountRaw: stakeAmtRaw,
    setAmountRaw: setStakeAmtRaw,
  } = useAmount(decimals || 18);
  const { address } = useAccount();
  const isWrongNetwork = useIsWrongNetwork();
  const [showCooldownDialog, setShowCooldownDialog] = useState(false);

  const txKey = cooldownSeconds ? `cooldown_${stakedTokenAddr}` : `withdraw_${stakedTokenAddr}`;
  const { pendingTxs } = useTxStatus();

  const isWithdrawing = pendingTxs?.has(txKey);
  const { write: withdraw } = useWriteTx({
    contractAbi: ERC20_VAULT_ABI,
    contractAddress: erc20VaultAddr,
    functionName: cooldownSeconds ? 'cooldown' : 'withdraw',
    functionArgs: cooldownSeconds ? [withdrawAmtBN] : [withdrawAmtBN, address, address],
    txKey,
    onTxConfirmed: () => {
      if (address) {
        fetchTokenBalance(stakedTokenAddr);
        fetchTokenBalance(erc20VaultAddr, undefined, 'vault');
        onConfirmed?.();
      }
    },
  });

  const timeArr = cooldownSeconds ? msToDaysHrsMinsSeconds((cooldownSeconds || 0) * 1000) : [];
  const timeUnits = ['day', 'hour', 'minute', 'second'];
  const cooldownString =
    timeArr.reduce(
      (result, cur, index) =>
        cur > 0
          ? result + ' ' + String(cur) + ' ' + timeUnits[index] + `${cur > 1 ? 's' : ''}`
          : result,
      ''
    ) || '0s';

  return (
    <>
      <ConfirmationDialog
        handleClose={() => {
          setShowCooldownDialog(false);
        }}
        open={showCooldownDialog}
        renderButtons={() => <></>}
        title={`${actionName} ${symbol}`}
      >
        <MaximizableAmount
          endAdornment={
            <FormControl sx={{ minWidth: { xs: 100, lg: 140 } }}>
              <Typography
                sx={{
                  fontSize: { xs: 14, sm: 18 },
                  color: theme => theme.colors.schema.primary,
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  columnGap: 0.75,
                }}
              >
                <Box
                  component="img"
                  src={erc20Info?.[stakedTokenAddr]?.iconUrl}
                  sx={{ width: 25 }}
                />
                {symbol}
              </Typography>
            </FormControl>
          }
          fullWidth
          setAmountRaw={setStakeAmtRaw}
          tokenSymbol={symbol}
          value={stakeAmtRaw}
        />
        <Stack direction="row" justifyContent="space-between" mt={2}>
          <Typography variant="subtitle1">
            {actionName === 'Unstake' ? 'STAKED' : 'AVAILABLE'} BALANCE:&nbsp;
            <Typography component="span" variant="subtitle1">
              {commify(
                actionName === 'Redeem'
                  ? formatWithPrecision(cooldownAmount || 0n)
                  : stakedFormattedBalance
              ) || '-'}
            </Typography>
            <Typography component="span" variant="subtitle1">
              {/* Use erc20 symbol instead of vault symbol as exchange rate is 1:1 */}
              &nbsp;{symbol}
            </Typography>
          </Typography>
          <MaxButton
            onClick={() => {
              const stringVal = formatUnits(
                actionName === 'Redeem' ? cooldownAmount || 0n : stakedBalance,
                decimals
              );

              setStakeAmtRaw(stringVal);
            }}
          />
        </Stack>
        <Typography mt={{ md: 1.25, xs: 1 }} variant="subtitle1">
          BALANCE:&nbsp;
          <Typography component="span" variant="subtitle1">
            {commify(formattedBalance) || '-'}
          </Typography>
          <Typography component="span" variant="subtitle1">
            &nbsp;{symbol}
          </Typography>
        </Typography>
        {cooldownSeconds && (
          <>
            <Stack alignItems="center" direction="row" gap={1} mt={{ md: 3, xs: 1.5 }}>
              <IconInfo sx={{ width: 20 }} />
              <Typography fontSize={14}>
                Tokens have to enter a {cooldownString} cooldown period before they are unstaked.
              </Typography>
            </Stack>
            <Stack alignItems="center" direction="row" gap={1} mt={{ md: 3, xs: 1.5 }}>
              <IconInfo sx={{ width: 20 }} />
              <Typography fontSize={14}>Rewards will not accrue on this token amount.</Typography>
            </Stack>
          </>
        )}
        {Boolean(cooldownAmount && cooldownSeconds) && (
          <Typography
            mt={{ md: 1.25, xs: 1 }}
            sx={{ bgcolor: '#F5F5DA', p: 2, mt: 2, fontSize: 12 }}
            variant="subtitle1"
          >
            <IconWarning sx={{ stroke: 'red', fill: 'none', width: 20, height: 20, mb: -0.8 }} />{' '}
            You have pending/finished cooldowns on{' '}
            <Typography component="span" sx={{ fontSize: 12, textDecoration: 'underline' }}>
              {formatWithPrecision(cooldownAmount || 0n)} {tokens[stakedTokenAddr]?.symbol}
            </Typography>
            . Proceeding will reset the cooldown period on this amount.
          </Typography>
        )}
        <LoadingButton
          disabled={
            !withdrawAmtBN || actionName === 'Redeem'
              ? (withdrawAmtBN || 0n) > (cooldownAmount || 0n)
              : (withdrawAmtBN || 0n) > (stakedBalance || 0n)
          }
          fullWidth
          loading={isWithdrawing}
          onClick={() => {
            withdraw();
          }}
          sx={{ mt: 5 }}
          variant="contained"
        >
          {cooldownSeconds ? 'Cooldown' : actionName}
        </LoadingButton>
      </ConfirmationDialog>
      <LoadingButton
        disabled={isWrongNetwork}
        loading={isWithdrawing}
        onClick={() => {
          setShowCooldownDialog(true);
        }}
        sx={sx}
        variant={actionName === 'Unstake' ? 'outlined' : 'contained'}
        {...rest}
      >
        {actionName}
      </LoadingButton>
    </>
  );
}
