import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import { useStakingInfo } from 'contexts/StakingInfoContext';
import { useTxStatus } from 'contexts/TxStatusContext';
import STAKE_ABI from 'core/contracts/STAKE_ABI';
import { commify, formatWithPrecision } from 'core/utils';
import { useAPY } from 'hooks/useAPY';
import { useComputedStakingValues } from 'hooks/useComputedStakingValues';
import { useIsWrongNetwork } from 'hooks/useIsWrongNetwork';
import { useWriteTx } from 'hooks/useWriteTx';
import { Statistic } from 'pages/Portfolio';
import { VaultRewardsTable } from 'pages/Rewards/VaultRewardsTable';
import { useEffect } from 'react';
import { Address } from 'viem';
import { useAccount } from 'wagmi';

const stAltConfig = window.appConfig?.vaults?.mach_alpha;
const stAltAddr = stAltConfig?.stakingContracts?.[0];
const reAltAddr = window.appConfig?.erc4626Vault;
const v2Vaults = window.appConfig?.v2Vaults;
const erc20Vaults = Object.values(v2Vaults || {})?.filter(cur => Boolean(cur?.erc20VaultAddr));

export default function Rewards() {
  const { address } = useAccount();
  const isWrongNetwork = useIsWrongNetwork();

  const {
    isAllVaultInfoFetched,
    refetchAllVaultUserInfo,
    refetchNodeUnclaimedRewards,
    stakingInfo,
  } = useStakingInfo();
  const { prices, tokens } = useStakedTokens();
  const { totalAccruingRewards, totalRewardsUsd } = useComputedStakingValues();

  const tokensAccruingRewardsUsd = (Object.keys(totalAccruingRewards || {}) as Address[])?.reduce(
    (acc, stakedTokenAddr) => ({
      ...acc,
      [stakedTokenAddr]:
        Number(formatWithPrecision(totalAccruingRewards?.[stakedTokenAddr])) *
        prices?.[stakedTokenAddr],
    }),
    {} as Record<Address, number>
  );
  const totalAccruingRewardsUsd = Object.values(tokensAccruingRewardsUsd || {})?.reduce(
    (sum, cur) => sum + cur,
    0
  );
  const reAltBalanceUsd =
    Number(formatWithPrecision(tokens?.[reAltAddr]?.balance || 0n)) * (prices?.[reAltAddr] || 0);

  const erc20VaultsBalanceUsd = erc20Vaults?.reduce(
    (sum, cur) =>
      sum +
      Number(formatWithPrecision(tokens?.[cur.erc20VaultAddr as Address]?.balance || 0n)) *
        (prices?.[cur.stakedTokenAddr as Address] || 0),
    0
  );

  const totalStakedBalanceUsd = totalAccruingRewardsUsd + reAltBalanceUsd + erc20VaultsBalanceUsd;

  const { reAltApy, stAltApr, vaultsApr } = useAPY();

  const portfolioApy = totalStakedBalanceUsd
    ? (vaultsApr * totalAccruingRewardsUsd + reAltApy * reAltBalanceUsd) / totalStakedBalanceUsd
    : 0;

  const portfolioPercentApy = (portfolioApy * 100).toFixed(2);

  const stAltPercentApr = (stAltApr * 100).toFixed(2);

  const altTokenAddr = stakingInfo?.mach_alpha?.[stAltAddr].stakedTokenAddr;
  const stAltRewards =
    Object.values(stakingInfo?.mach_alpha?.[stAltAddr]?.rewards || {})?.[0]?.[
      altTokenAddr || '0x'
    ] || 0n; // assume only 1 operator per contract

  const distMap = stakingInfo?.mach_alpha?.[stAltAddr]?.distributionMap;
  const stAltOperator = stakingInfo?.mach_alpha?.[stAltAddr]?.operatorAddresses?.[0];
  const distributions = Object.values(distMap || {});
  const txKey = 'claimRewards_stALT';
  const { pendingTxs } = useTxStatus();
  const isLoading = pendingTxs?.has(txKey);
  const { write: claim } = useWriteTx({
    contractAbi: STAKE_ABI,
    contractAddress: stAltAddr,
    functionName: 'claimRewardsBatch',
    functionArgs: [
      distributions.map(dist => dist.id),
      new Array(distributions.length).fill(address),
      new Array(distributions.length).fill(stAltOperator),
      new Array(distributions.length).fill(stAltRewards),
    ],
    onTxConfirmed: () => {
      refetchNodeUnclaimedRewards('mach_alpha', stAltAddr, stAltOperator);
    },
    txKey,
  });

  useEffect(() => {
    if (address && isAllVaultInfoFetched) {
      refetchAllVaultUserInfo();
      const intervalId = setInterval(() => {
        refetchAllVaultUserInfo();
      }, window.appConfig?.avgBlockTimeSeconds * 1000 || 30000);

      return () => {
        clearInterval(intervalId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isAllVaultInfoFetched]);

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { sm: '2fr 3fr', xs: 'auto' },
          width: '100%',
        }}
      >
        <Statistic
          sx={{ gridArea: { xs: 'unset', sm: portfolioApy > 0 ? 'unset' : '1 / 1 / 3 / 2' } }}
          title="CURRENT TOTAL REWARDS"
          value={`$${commify(totalRewardsUsd?.toFixed(2))}`}
        />
        {portfolioApy > 0 && (
          <Statistic
            title="PORTFOLIO EST. APY"
            value={vaultsApr || reAltApy ? `${commify(portfolioPercentApy)}%` : 'N/A'}
          />
        )}
        <Grid
          container
          rowGap={2}
          sx={{
            gridArea: { xs: 'unset', sm: '1 / 2 / 3 / 2' },
            px: { xs: 3, lg: 4 },
            py: 3,
            background: 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)',
            borderBottom: '1px solid #20272317',
          }}
        >
          <Grid item xs={12}>
            <Typography fontWeight={500} variant="h2">
              Staking
            </Typography>
          </Grid>
          {stAltRewards > 0 ? (
            <>
              <Grid item md={4} sm={6} xs={12}>
                <Stack rowGap={1}>
                  <Typography
                    sx={{ '&': { fontWeight: 500, fontSize: 13 } }}
                    textTransform="uppercase"
                  >
                    Staked Token
                  </Typography>
                  <Typography sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} variant="h6">
                    <Box
                      component="img"
                      src={'/icons/iconAltLayer.svg'}
                      sx={{
                        width: 24,
                        marginRight: 0.5,
                      }}
                    />{' '}
                    ALT
                  </Typography>
                </Stack>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Stack rowGap={1}>
                  <Typography
                    sx={{ '&': { fontWeight: 500, fontSize: 13 } }}
                    textTransform="uppercase"
                  >
                    APR
                  </Typography>
                  <Typography variant="h6">{stAltPercentApr}%</Typography>
                </Stack>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Stack rowGap={1}>
                  <Typography
                    sx={{ '&': { fontWeight: 500, fontSize: 13 } }}
                    textTransform="uppercase"
                  >
                    Current Rewards
                  </Typography>
                  {Object.values(distMap || {}).map(dist => (
                    <Tooltip
                      key={dist.id}
                      placement="right"
                      title={`~$${commify(
                        (
                          Number(formatWithPrecision(stAltRewards)) * prices?.[dist?.rewardToken]
                        )?.toFixed(2)
                      )}`}
                    >
                      <Typography
                        alignItems="center"
                        component="span"
                        key={dist.id}
                        sx={{
                          width: 'max-content',
                          display: 'flex',
                          columnGap: 0.25,
                          cursor: 'default',
                          ':hover': { textDecoration: 'underline' },
                        }}
                      >
                        <Typography variant="h6">
                          {commify(formatWithPrecision(stAltRewards))}
                        </Typography>
                        <Typography variant="h6">ALT</Typography>
                      </Typography>
                    </Tooltip>
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  disableTouchRipple
                  disabled={!address || isWrongNetwork}
                  loading={isLoading}
                  onClick={claim}
                  sx={theme => ({
                    '&&': {
                      [theme.breakpoints.down('sm')]: {
                        minWidth: { xs: '100%', md: 'unset' },
                      },
                    },
                  })}
                  variant="contained"
                >
                  <Typography color="#ffffff">Claim</Typography>
                </LoadingButton>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Typography>You do not have any claimable rewards</Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      <Stack
        sx={{
          flex: 1,
          px: { sm: 4, xs: 0 },
          background: 'linear-gradient(154.71deg, #FFFFFF 0%, #F7F7F7 100%)',
        }}
      >
        <Typography
          mb={{ xs: 0, sm: 2 }}
          mt={{ xs: 0, sm: 4 }}
          sx={{
            background: {
              xs: 'linear-gradient(118.58deg, #F6F6F6 0%, #E7E7E7 100%)',
              sm: 'inherit',
            },
            p: { xs: 3, sm: 0 },
          }}
          variant="h2"
        >
          Vaults
        </Typography>
        <VaultRewardsTable />
      </Stack>
    </>
  );
}
