import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { paths } from 'App';
import IconArrowDownFilled from 'components/icons/IconArrowDownFilled';
import { LaunchPoolLabel, NewLabel, StakingPoolLabel } from 'components/VaultLabels';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import { useV2Vaults, V2VaultData } from 'contexts/V2VaultsContext';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Address } from 'viem';

const v2Vaults = window.appConfig?.v2Vaults;

const VaultCard: FC<{ vault: V2VaultData; isNew: boolean }> = ({ isNew, vault }) => {
  const navigate = useNavigate();
  const { tokens } = useStakedTokens();
  const isLaunchPool = !v2Vaults?.[vault?.id]?.isLive;
  const stakedToken =
    tokens?.[vault?.stakedTokenAddr as Address]?.symbol ||
    (vault?.tokenSymbol === '[REDACTED]' ? '' : vault?.tokenSymbol);

  return (
    <Stack
      className="hover-grow"
      justifyContent="space-between"
      onClick={() => navigate(paths.StakingPools?.replace(':vaultId', String(vault?.id)))}
      sx={{
        position: 'relative',
        width: '100%',
        minHeight: { xs: '15rem', md: '20rem', lg: '30rem' },
        background: 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)',
        p: 3,
        cursor: 'pointer',
        borderBottom: '1px solid #20272317',
        ':hover': {
          zIndex: 1,
          transform: 'scale(1.005)',
        },
      }}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack alignItems="center" columnGap={2} direction="row">
          <Box component="img" src={vault?.iconUrl} sx={{ width: 38 }} />
          <Typography fontWeight={500} variant="h2">
            {vault?.name}
          </Typography>
          {isNew && <NewLabel />}
        </Stack>
        {isLaunchPool ? <LaunchPoolLabel /> : <StakingPoolLabel />}
      </Stack>
      <Stack alignItems="center" direction="row" justifyContent="flex-start">
        <IconArrowDownFilled sx={{ transform: 'rotate(-90deg)', mr: 2, width: 38, height: 38 }} />
        <Typography>Restake ETH / ALT {stakedToken ? `/ ${stakedToken}` : ''}</Typography>
        <Box
          component="img"
          src={vault?.iconUrl}
          sx={{
            position: 'absolute',
            height: '70%',
            right: 20,
            bottom: 0,
            filter: 'grayscale()',
            opacity: 0.1,
          }}
        />
      </Stack>
    </Stack>
  );
};

export default function VaultSelection() {
  const { vaultMap } = useV2Vaults();

  return (
    <Grid container>
      {/* V2 VAULTS (OPT-IN) */}
      {Object.values(vaultMap)
        ?.sort((a, b) => Number(b?.startTime - a?.startTime))
        ?.map((vault, index) => (
          <Grid item key={vault?.id} sm={6} xs={12}>
            <VaultCard isNew={index === 0} vault={vault} />
          </Grid>
        ))}
    </Grid>
  );
}
