import { useStakedTokens } from 'contexts/StakedTokensContext';
import { StakingContractInfo, useStakingInfo } from 'contexts/StakingInfoContext';
import { formatWithPrecision, SECONDS_IN_YEAR, WEEKS_IN_YEAR } from 'core/utils';
import { useMemo } from 'react';
import { Address } from 'viem';

import { useComputedStakingValues } from './useComputedStakingValues';

const vaultsConfigs = window.appConfig?.vaults || {};

export const useAPY = () => {
  const { stakingInfo } = useStakingInfo();
  const { prices } = useStakedTokens();

  const stAltContract =
    stakingInfo?.mach_alpha?.[vaultsConfigs?.mach_alpha?.stakingContracts?.[0]] || {};
  const sumEmissionsPerYearUsd = Object.values(stAltContract?.distributionMap || {}).reduce(
    (sum, dist) => {
      const distEpy = dist?.eps * SECONDS_IN_YEAR;
      const distEpyUsd = Number(formatWithPrecision(distEpy)) * (prices?.[dist.rewardToken] || 0);

      return sum + distEpyUsd;
    },
    0
  );

  const stAltGlobalVotingStakeUsd =
    Number(formatWithPrecision(stAltContract?.globalVotingStake || 0n)) *
    (prices?.[stAltContract?.stakedTokenAddr as Address] || 0);

  const stAltApr =
    stAltGlobalVotingStakeUsd > 0 ? sumEmissionsPerYearUsd / stAltGlobalVotingStakeUsd : 0;

  const reAltApy = (1 + stAltApr / WEEKS_IN_YEAR) ** WEEKS_IN_YEAR - 1;

  const { contractsEmissionsPerSec, votingStakeTotalsByContract } = useComputedStakingValues();
  const vaultsApr = useMemo(() => {
    const totalUserVotingStakeUsd = Object.entries(votingStakeTotalsByContract || {})?.reduce(
      (totalUsd, [stakingContractAddr, contractTotals]) => {
        if (contractTotals?.isLaunchPool) {
          // ignore staked balance in APR calc if contract is part of a launchPool vault
          return totalUsd;
        }

        const allStakingContracts = Object.values(stakingInfo || {})?.reduce(
          (acc, stakingInfo) => [...acc, ...Object.values(stakingInfo || {})],
          [] as StakingContractInfo[]
        );
        const stakedTokenAddr = allStakingContracts.find(
          cur => cur.stakingContractAddr === stakingContractAddr
        )?.stakedTokenAddr;

        const contractVotingStakedUsd =
          Number(formatWithPrecision(contractTotals?.user)) *
          (prices[stakedTokenAddr || '0x'] || 0);

        return totalUsd + contractVotingStakedUsd;
      },
      0
    );

    const vaultsTotalEmissionValueUsd = Object.entries(contractsEmissionsPerSec || {})?.reduce(
      (totalUsd, [stakingContractAddr, contractEmissions]) => {
        return (
          totalUsd +
          Object.entries(contractEmissions || {})?.reduce(
            (emissionsUsd, [rewardTokenAddr, emissionPerSec]) =>
              emissionsUsd +
              (votingStakeTotalsByContract?.[stakingContractAddr as Address]?.global
                ? Number(
                    formatWithPrecision(
                      (emissionPerSec *
                        SECONDS_IN_YEAR *
                        votingStakeTotalsByContract?.[stakingContractAddr as Address]?.user) /
                        votingStakeTotalsByContract?.[stakingContractAddr as Address]?.global
                    )
                  ) * prices[rewardTokenAddr as Address]
                : 0),
            0
          )
        );
      },
      0
    );

    return totalUserVotingStakeUsd > 0 ? vaultsTotalEmissionValueUsd / totalUserVotingStakeUsd : 0;
  }, [stakingInfo, votingStakeTotalsByContract, contractsEmissionsPerSec, prices]);

  return { reAltApy, stAltApr, vaultsApr };
};
