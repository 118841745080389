import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconTwitter({
  fillColor,
  ...props
}: SvgIconProps & { fillColor?: string }) {
  return (
    <SvgIcon
      height="39"
      sx={{
        height: '20px',
        width: 'auto',
      }}
      viewBox="0 0 39 39"
      width="39"
      {...props}
    >
      <path
        d="M23.21 16.5137L37.7286 0H34.2882L21.6819 14.3388L11.6131 0H0L15.2258 21.6827L0 39H3.44075L16.7536 23.8579L27.3869 39H39L23.2097 16.5137H23.2107H23.21ZM18.4975 21.8735L16.9548 19.7145L4.68029 2.53424H9.96474L19.8707 16.3992L21.4134 18.5582L34.2898 36.5806H29.0054L18.4979 21.8741V21.8732L18.4975 21.8735Z"
        fill={fillColor || '#373741'}
        id="Vector"
      />
    </SvgIcon>
  );
}
