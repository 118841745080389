import { Box, Button, Grid, GridProps, Stack, Tooltip, Typography } from '@mui/material';
import IconTimer from 'components/icons/IconTimer';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import { useStakingInfo } from 'contexts/StakingInfoContext';
import ERC20_VAULT_ABI from 'core/contracts/ERC20_VAULT_ABI';
import { commify, formatWithPrecision, getTimeLeft } from 'core/utils';
import { useComputedStakingValues } from 'hooks/useComputedStakingValues';
import { useContractRead } from 'hooks/useContractRead';
import { FC, ReactNode, useEffect, useState } from 'react';
import { V2VaultConfig } from 'types';
import { Address } from 'viem';
import { useAccount } from 'wagmi';

import CooldownButton from './CooldownButton';
import RedeemButton from './RedeemButton';
import UnstakeERC20VaultButton from './UnstakeERC20VaultButton';
import { UnstakeWindowState } from './VaultsTableRow';

const v2VaultsConfigs = window.appConfig?.v2Vaults;
const vaultsConfigs = window.appConfig?.vaults;
const reAltAddr = window.appConfig?.erc4626Vault;

export const DetailsCardMobile: FC<
  {
    vaultConfig: Omit<V2VaultConfig, 'tokenSymbol'>;
    status: string;
    balance: ReactNode;
    apy?: string;
  } & GridProps
> = ({ apy, balance, children, status, sx, vaultConfig, ...rest }) => (
  <Grid
    container
    sx={{
      background: 'linear-gradient(154.71deg, #FFFFFF 0%, #F7F7F7 100%)',
      '&>*': {
        borderBottom: '1px solid #20272308',
        p: 3,
      },
      display: { xs: 'flex', sm: 'none' },
      ...sx,
    }}
    {...rest}
  >
    <Grid item xs={12}>
      <Stack alignItems="center" direction="row" gap={1}>
        <Box
          component="img"
          src={vaultConfig?.iconUrl}
          sx={{
            width: 30,
          }}
        />
        <Typography variant="h5">{vaultConfig?.name}</Typography>
      </Stack>
    </Grid>
    {apy !== '' && (
      <>
        <Grid item xs={6}>
          <Typography variant="h6">APY</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography textAlign="right" variant="h6">
            {apy || (vaultConfig?.isLive ? 'TBD' : 'LaunchPool')}
          </Typography>
        </Grid>
      </>
    )}
    <Grid item xs={6}>
      <Typography variant="h6">BALANCE</Typography>
    </Grid>
    <Grid item xs={6}>
      <Stack direction="row" justifyContent="flex-end">
        {balance}
      </Stack>
    </Grid>
    <Grid item xs={6}>
      <Typography variant="h6">STATUS</Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography textAlign="right" variant="h6">
        {status}
      </Typography>
    </Grid>
    {children}
  </Grid>
);

export const V1VaultDetailsCard: FC<
  GridProps & {
    vaultId: string;
    apy?: string;
  }
> = ({ apy, sx, vaultId, ...rest }) => {
  const vaultConfig = vaultsConfigs?.[vaultId];
  const { nodeBalances } = useComputedStakingValues();
  const { refetchNodeCooldownInfo, refetchNodeStakedBalance, stakingInfo } = useStakingInfo();
  const v1VaultAddr = vaultsConfigs?.[vaultId]?.stakingContracts?.[0];
  const { operatorAddresses, stakedTokenAddr, stakedTokenSymbol } =
    stakingInfo?.[vaultId]?.[v1VaultAddr] || {};
  const v1OperatorAddr = operatorAddresses?.[0];
  const v1VaultBal = nodeBalances?.[vaultId]?.[v1VaultAddr]?.[v1OperatorAddr];

  const [unstakeWindowState, setUnstakeWindowState] = useState(UnstakeWindowState.NOT_STARTED);
  const { prices } = useStakedTokens();
  const cooldownSeconds = stakingInfo?.[vaultConfig?.id]?.[v1VaultAddr]?.cooldownSeconds;
  const { amount: cooldownAmount, timestamp: cooldownTimestamp } = stakingInfo?.[vaultConfig?.id]?.[
    v1VaultAddr
  ]?.cooldowns?.[v1OperatorAddr] || { timestamp: 0, amount: 0n };

  // on UI, wait for 1 additional block to avoid gas estimation issues due to last block timestamp being used instead of current time
  const avgBlockTimeSeconds = Number(window?.appConfig?.avgBlockTimeSeconds);
  const redeemStartTimeSeconds = (cooldownSeconds || 0) + cooldownTimestamp + avgBlockTimeSeconds;

  const [currentTimeSeconds, setCurrentTimeSeconds] = useState(Math.round(Date.now() / 1000));

  useEffect(() => {
    if (window !== undefined) {
      const timer = setInterval(() => {
        const currentSeconds = Math.round(Date.now() / 1000);

        if (currentSeconds === redeemStartTimeSeconds) {
          refetchNodeCooldownInfo(vaultConfig?.id, v1VaultAddr, v1OperatorAddr);
        }

        setCurrentTimeSeconds(currentSeconds);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [
    v1OperatorAddr,
    redeemStartTimeSeconds,
    refetchNodeCooldownInfo,
    vaultConfig?.id,
    v1VaultAddr,
  ]);

  useEffect(() => {
    setUnstakeWindowState(
      cooldownTimestamp === 0 || !cooldownTimestamp
        ? UnstakeWindowState.NOT_STARTED
        : currentTimeSeconds < redeemStartTimeSeconds
        ? UnstakeWindowState.COOLING_DOWN
        : UnstakeWindowState.REDEEMABLE
    );
  }, [cooldownTimestamp, currentTimeSeconds, redeemStartTimeSeconds]);

  return (
    <>
      {v1VaultBal?.accruingRewards && (
        <DetailsCardMobile
          apy={apy}
          balance={
            <Tooltip
              placement="right"
              title={`~$${commify(
                (
                  Number(formatWithPrecision(v1VaultBal?.accruingRewards || 0n)) *
                  (prices?.[stakedTokenAddr || '0x'] || 0)
                )?.toFixed(2)
              )}`}
            >
              <Typography
                alignSelf="flex-end"
                component="span"
                sx={{ cursor: 'default', ':hover': { textDecoration: 'underline' } }}
                textAlign="right"
              >
                {commify(formatWithPrecision(v1VaultBal?.accruingRewards || 0n))}{' '}
                {stakedTokenSymbol}
              </Typography>
            </Tooltip>
          }
          status="Staked"
          sx={{
            background: 'inherit',
            ...sx,
          }}
          vaultConfig={vaultConfig}
          {...rest}
        >
          <Grid item xs={12}>
            <CooldownButton
              fullWidth
              operatorAddr={v1OperatorAddr}
              stakingContractAddr={v1VaultAddr}
              vaultConfig={vaultConfig}
            />
          </Grid>
        </DetailsCardMobile>
      )}
      {(v1VaultBal?.withdrawable || v1VaultBal?.inCooldown) && (
        <DetailsCardMobile
          apy={''}
          balance={
            <Tooltip
              placement="right"
              title={`~$${commify(
                (
                  Number(
                    formatWithPrecision(v1VaultBal?.withdrawable || v1VaultBal?.inCooldown || 0n)
                  ) * (prices?.[stakedTokenAddr as Address] || 0)
                )?.toFixed(2)
              )}`}
            >
              <Stack alignItems="center" direction="row">
                <Typography
                  component="span"
                  sx={{ cursor: 'default', ':hover': { textDecoration: 'underline' } }}
                >
                  {commify(
                    formatWithPrecision(v1VaultBal?.withdrawable || v1VaultBal?.inCooldown || 0n)
                  )}{' '}
                  {stakedTokenSymbol}
                </Typography>
              </Stack>
            </Tooltip>
          }
          status={
            unstakeWindowState === UnstakeWindowState.COOLING_DOWN ? 'Pending Cooldown' : 'Unstaked'
          }
          sx={{
            background: 'inherit',
            ...sx,
          }}
          vaultConfig={vaultConfig}
          {...rest}
        >
          <Grid item xs={12}>
            {unstakeWindowState === UnstakeWindowState.COOLING_DOWN ? (
              <Button disabled fullWidth variant="contained">
                <Typography color="white" sx={{ fontWeight: 600, fontStyle: 'italic' }}>
                  <IconTimer
                    sx={{ width: 12, height: 12, display: { xs: 'inline', md: 'none' } }}
                  />
                  {getTimeLeft(new Date(redeemStartTimeSeconds * 1000))}{' '}
                  <Typography color="white" component="span" sx={{ fontWeight: 600 }}>
                    remaining...
                  </Typography>
                </Typography>
              </Button>
            ) : (
              <RedeemButton
                cooldownAmount={cooldownAmount}
                currentTimeSeconds={currentTimeSeconds}
                fullWidth
                onConfirmed={() => {
                  refetchNodeCooldownInfo(vaultConfig?.id, v1VaultAddr, v1OperatorAddr);
                  refetchNodeStakedBalance(vaultConfig?.id, v1VaultAddr, v1OperatorAddr);
                }}
                producerNode={v1OperatorAddr}
                redeemStartTimeSeconds={redeemStartTimeSeconds}
                stakingContractAddr={v1VaultAddr}
                vaultConfig={vaultConfig}
              />
            )}
          </Grid>
        </DetailsCardMobile>
      )}
    </>
  );
};

const ERC20DetailsCard: FC<{ vaultConfig: V2VaultConfig }> = ({ vaultConfig }) => {
  const erc20Addr = vaultConfig?.stakedTokenAddr || '0x';
  const vaultAddr = vaultConfig?.erc20VaultAddr || '0x';
  const { prices, tokens } = useStakedTokens();
  const { address } = useAccount();

  const erc20Token = tokens?.[erc20Addr] || {};
  const stakedToken = tokens?.[vaultAddr] || {};

  const { data: cooldownSeconds } = useContractRead<number>({
    address: vaultAddr,
    abi: ERC20_VAULT_ABI,
    functionName: 'cooldownSeconds',
    switchRpcOnErr: false,
    onError: () => {},
  });

  const { data: cooldownInfo, refetch: refetchCooldownInfo } = useContractRead<[bigint, number]>({
    address: vaultAddr,
    abi: ERC20_VAULT_ABI,
    functionName: 'cooldowns',
    args: [address],
    enabled: Boolean(address),
    switchRpcOnErr: false,
    onError: () => {},
  });
  const [cooldownAmount, cooldownTimestamp] = cooldownInfo || [];
  // on UI, wait for 1 additional block to avoid gas estimation issues due to last block timestamp being used instead of current time
  const avgBlockTimeSeconds = Number(window?.appConfig?.avgBlockTimeSeconds);
  const redeemStartTimeSeconds =
    avgBlockTimeSeconds + ((cooldownSeconds as number) || 0) + ((cooldownTimestamp as number) || 0);

  const [currentTimeSeconds, setCurrentTimeSeconds] = useState(Math.round(Date.now() / 1000));

  useEffect(() => {
    if (window !== undefined) {
      const timer = setInterval(() => {
        const currentSeconds = Math.round(Date.now() / 1000);

        if (currentSeconds === redeemStartTimeSeconds) {
          refetchCooldownInfo();
        }

        setCurrentTimeSeconds(currentSeconds);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [redeemStartTimeSeconds, refetchCooldownInfo]);

  return (
    <>
      {stakedToken?.balance && (
        <DetailsCardMobile
          balance={
            <Tooltip
              placement="right"
              title={`~$${commify(
                (
                  Number(formatWithPrecision(stakedToken?.balance || 0n)) *
                  (prices?.[erc20Addr] || 0)
                )?.toFixed(2)
              )}`}
            >
              <Typography
                sx={{ cursor: 'default', ':hover': { textDecoration: 'underline' } }}
                textAlign="right"
              >
                {/* Show stakeToken balance with erc20Token symbol as exchange rate is 1:1 */}
                {commify(formatWithPrecision(stakedToken?.balance || 0n))} {erc20Token?.symbol}
              </Typography>
            </Tooltip>
          }
          status="Staked"
          vaultConfig={vaultConfig}
        >
          <Grid item xs={12}>
            <UnstakeERC20VaultButton
              cooldownInfo={cooldownInfo}
              cooldownSeconds={cooldownSeconds}
              fullWidth
              onConfirmed={() => refetchCooldownInfo()}
              vaultConfig={vaultConfig}
            />
          </Grid>
        </DetailsCardMobile>
      )}
      {cooldownAmount && (
        <DetailsCardMobile
          balance={
            <Tooltip
              placement="right"
              title={`~$${commify(
                (
                  Number(formatWithPrecision(cooldownAmount || 0n)) * (prices?.[erc20Addr] || 0)
                )?.toFixed(2)
              )}`}
            >
              <Typography
                sx={{ cursor: 'default', ':hover': { textDecoration: 'underline' } }}
                textAlign="right"
              >
                {/* Show stakeToken balance with erc20Token symbol as exchange rate is 1:1 */}
                {commify(formatWithPrecision(cooldownAmount || 0n))} {erc20Token?.symbol}
              </Typography>
            </Tooltip>
          }
          status={currentTimeSeconds < redeemStartTimeSeconds ? 'Pending cooldown' : 'Unstaked'}
          vaultConfig={vaultConfig}
        >
          <Grid item xs={12}>
            {currentTimeSeconds < redeemStartTimeSeconds ? (
              <Typography sx={{ fontWeight: 600, fontStyle: 'italic' }} textAlign="center">
                {getTimeLeft(new Date(redeemStartTimeSeconds * 1000))} remaining...
              </Typography>
            ) : (
              <UnstakeERC20VaultButton
                actionName="Redeem"
                cooldownInfo={cooldownInfo}
                fullWidth
                onConfirmed={() => refetchCooldownInfo()}
                vaultConfig={vaultConfig}
              />
            )}
          </Grid>
        </DetailsCardMobile>
      )}
    </>
  );
};

const PortfolioDetailsCardMobile: FC<{ vaultId: string }> = ({ vaultId }) => {
  const vaultConfig = v2VaultsConfigs?.[Number(vaultId)] || vaultsConfigs?.[vaultId];
  const { prices, tokens } = useStakedTokens();
  const reAltBalance = tokens?.[reAltAddr]?.balance;

  return (
    <>
      {
        // V1 vaults e.g. xterio legacy
        vaultsConfigs?.[vaultId] && <V1VaultDetailsCard vaultId={vaultId} />
      }
      {
        // V2 vaults e.g. xterio, dodo
        v2VaultsConfigs?.[Number(vaultId)] && Boolean(tokens?.[reAltAddr]?.balance) && (
          <DetailsCardMobile
            balance={
              <Tooltip
                placement="right"
                title={`~$${commify(
                  (
                    Number(formatWithPrecision(reAltBalance || 0n)) * (prices?.[reAltAddr] || 0)
                  )?.toFixed(2)
                )}`}
              >
                <Typography
                  sx={{ cursor: 'default', ':hover': { textDecoration: 'underline' } }}
                  textAlign="right"
                >
                  {commify(formatWithPrecision(reAltBalance || 0n))} reALT
                </Typography>
              </Tooltip>
            }
            status="Delegated"
            vaultConfig={vaultConfig}
          />
        )
      }
      {
        // ERC20 Vaults
        vaultConfig?.erc20VaultAddr && <ERC20DetailsCard vaultConfig={vaultConfig} />
      }
    </>
  );
};

export default PortfolioDetailsCardMobile;
