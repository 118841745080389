import GlobalStyles from '@mui/material/GlobalStyles';

export const globalStyles = (
  <GlobalStyles
    styles={theme => ({
      a: {
        textDecoration: 'none',
        fontSize: '14px',
        color: theme.colors.functional.text.link,
      },
    })}
  />
);
