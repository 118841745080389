import { GetComponentStyleFunc } from './types';

export const muiPopover: GetComponentStyleFunc<'MuiPopover'> = () => {
  return {
    styleOverrides: {
      root: {
        '&.MuiModal-root.MuiPopover-root > .MuiBackdrop-root.MuiModal-backdrop': {
          background: 'transparent',
        },
        '& > .MuiMenu-paper': {
          background: '#ffffff',
          border: '1px solid #efefef',
          borderRadius: '6px',
          boxShadow: '0px 12px 12px 0px rgba(0,0,0,0.12)',
        },
      },
    },
  };
};
