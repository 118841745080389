import { GetComponentStyleFunc } from './types';

export const muiMenuList: GetComponentStyleFunc<'MuiMenuList'> = () => {
  return {
    styleOverrides: {
      root: {},
    },
  };
};

export const muiMenuItem: GetComponentStyleFunc<'MuiMenuItem'> = theme => {
  return {
    styleOverrides: {
      root: {
        '&.Mui-selected.MuiButtonBase-root': {
          background: theme.colors.functional.container.primary,
        },
        '&:hover': {
          background: theme.colors.functional.container.primary,
        },
      },
    },
  };
};
