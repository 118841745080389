import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconExtLink({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      height="14"
      sx={{ position: 'relative', top: 8, left: 4, width: 14, ...sx }}
      viewBox="0 0 14 14"
      width="14"
      {...props}
    >
      <path
        d="M2.6001 10.4L11.6001 1.40002M11.6001 1.40002H2.6001M11.6001 1.40002V10.4"
        stroke="currentColor"
      />
    </SvgIcon>
  );
}
