import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconPortfolio({ ...props }: SvgIconProps) {
  return (
    <SvgIcon fill="none" height="26" viewBox="0 0 26 26" width="26" {...props}>
      <path
        clipRule="evenodd"
        d="M13 3H22V6.59998H13V3ZM13 7.59998V8V9V11.2H22V7.59998H13ZM22 12.2H13V12.6V13.6V16H22V12.2ZM12 12.2V11.2V9H3V12.6H12V12.2ZM12 8V7.59998V6.59998V3V2H13H22H23V3V6.59998V7.59998V11.2V12.2V16V17H22H13V17.2V18.2V22V23H12H3H2V22V18.2V17.2V13.6V12.6V9V8H3H12ZM12 17V17.2H3V13.6H12V16V17ZM3 22V18.2H12V22H3Z"
        fill="#202723"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
