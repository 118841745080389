import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import { FC } from 'react';

export const LiveLabel: FC<BoxProps> = ({ sx }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: 'rgb(176,203,187)',
        color: theme.colors.functional.text.primary,
        borderRadius: '1rem',
        px: 1,
        py: 0.5,
        fontWeight: 600,
        width: 'max-content',
        ...sx,
      }}
    >
      Live
    </Box>
  );
};

export const LaunchPoolLabel: FC<BoxProps> = ({ sx }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: '#F3FAE5',
        color: theme.colors.functional.text.primary,
        px: 2,
        py: 1,
        width: 'max-content',
        ...sx,
      }}
    >
      <Typography sx={{ fontWeight: 400, fontSize: 14 }}>LAUNCHPOOL</Typography>
    </Box>
  );
};

export const StakingPoolLabel: FC<BoxProps> = ({ sx }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: '#D3DAFF55',
        color: theme.colors.functional.text.primary,
        px: 2,
        py: 1,
        width: 'max-content',
        ...sx,
      }}
    >
      <Typography sx={{ fontWeight: 400, fontSize: 14 }}>STAKINGPOOL</Typography>
    </Box>
  );
};

export const NewLabel: FC<BoxProps> = ({ sx }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: '#CBEE2D80',
        color: theme.colors.functional.text.primary,
        px: 2,
        py: 1,
        width: 'max-content',
        ...sx,
      }}
    >
      <Typography sx={{ fontWeight: 400, fontSize: 14 }}>NEW</Typography>
    </Box>
  );
};
