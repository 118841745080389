import './font.css';

import { createTheme, responsiveFontSizes } from '@mui/material';

import { muiBreadcrumbs } from './MuiBreadcrumbs';
import { muiButton, muiButtonBase } from './MuiButton';
import {
  muiDialog,
  muiDialogActions,
  muiDialogContent,
  muiDialogContentText,
  muiDialogTitle,
} from './MuiDialog';
import { muiInput, muiOutlinedInput } from './MuiInput';
import { muiLink } from './MuiLink';
import { muiMenuItem, muiMenuList } from './MuiMenu';
import { muiPopover } from './MuiPopover';
import { muiPopper } from './MuiPopper';
import { muiRadio } from './MuiRadio';
import { muiSlider } from './MuiSlider';
import { muiSvgIcon } from './MuiSvgIcon';
import { muiTab, muiTabs } from './MuiTab';
import { muiTable } from './MuiTable';
import { muiTableCell } from './MuiTableCell';
import { muiTextField } from './MuiTextField';
import { muiTypography } from './MuiTypography';
import { Colors } from './types';

const colors: Colors = {
  schema: {
    primary: 'rgb(83,81,155)',
    secondary: '#9092FE',
    success: '#39BA72',
    info: '#6667ab',
    error: '#FF6F7D',
    failure: '#F83B4C',
    disable: '#BAC6AB',
    pending: '#48F20A',
    warning: '#F86503',
  },
  functional: {
    text: {
      primary: '#202723',
      second: '#AFB0CC',
      third: '#A4A4B2',
      link: '#6667AB',
      lint: '#AFB0CC',
    },
    subject: {
      first: '#afb0cc',
      third: '#646471',
      border: '#3d3d49',
      inputBorder: '#5c5d98',
    },
    container: {
      primary: 'white',
      default: 'white',
      dark: '#28282F',
      button: '#6266db',
      lint: '#e7e7fa',
      first: '#f6f6f6',
    },
  },
};

const baseTheme = createTheme({
  typography: {
    fontFamily: 'ABCDiatypeSemiMono',
  },
  colors,
  palette: {
    text: { primary: colors.functional.text.primary, secondary: colors.functional.text.lint },
    primary: { main: colors.schema.primary },
    secondary: { main: colors.schema.secondary },
    error: { main: colors.schema.error },
    warning: { main: colors.schema.error },
    info: { main: colors.schema.info },
    success: { main: colors.schema.success },
    action: {
      disabled: colors.functional.text.primary,
    },
  },
});

export const theme = responsiveFontSizes({
  ...baseTheme,

  components: {
    MuiMenuItem: muiMenuItem(baseTheme),
    MuiMenuList: muiMenuList(baseTheme),
    MuiPopover: muiPopover(baseTheme),
    MuiBreadcrumbs: muiBreadcrumbs(baseTheme),
    MuiRadio: muiRadio(baseTheme),
    MuiButtonBase: muiButtonBase(baseTheme),
    MuiButton: muiButton(baseTheme),
    MuiTextField: muiTextField(baseTheme),
    MuiOutlinedInput: muiOutlinedInput(baseTheme),
    MuiInput: muiInput(baseTheme),
    MuiLink: muiLink(baseTheme),
    MuiTypography: muiTypography(baseTheme),
    MuiSlider: muiSlider(baseTheme),
    MuiSvgIcon: muiSvgIcon(baseTheme),
    MuiDialogTitle: muiDialogTitle(baseTheme),
    MuiDialogActions: muiDialogActions(baseTheme),
    MuiDialogContent: muiDialogContent(baseTheme),
    MuiDialogContentText: muiDialogContentText(baseTheme),
    MuiDialog: muiDialog(baseTheme),
    MuiTab: muiTab(baseTheme),
    MuiTabs: muiTabs(baseTheme),
    MuiTable: muiTable(baseTheme),
    MuiTableCell: muiTableCell(baseTheme),
    MuiPopper: muiPopper(baseTheme),
    MuiCssBaseline: {
      styleOverrides: `
      
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .hover-grow { 
          transition: all .2s ease-in-out; 
        }
          
        .hover-grow:hover { 
          transform: scale(1.05); 
        }

        /* Works for Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }
        body {
          background: linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%);
        }
        html {
          scroll-behavior: smooth;
        }
      `,
    },
  },
});
