import { GetComponentStyleFunc } from './types';

export const muiLink: GetComponentStyleFunc<'MuiLink'> = theme => {
  return {
    styleOverrides: {
      root: {
        fontSize: '14px',
        textDecoration: 'none',
        color: theme.colors.functional.text.primary,
      },
    },
  };
};
