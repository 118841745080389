import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconArrowForward({ ...props }: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 28 28" {...props}>
      <g fill="none" fillRule="evenodd" id="页面-1" stroke="none" strokeWidth="1">
        <g id="Restake2" transform="translate(-942, -304)">
          <g id="chevron--right备份-2" transform="translate(942, 304)">
            <rect height="28" id="_x3C_Transparent_Rectangle_x3E_" width="28" x="0" y="0"></rect>
            <path
              d="M10.5197612,5.03430335 L18.6397612,13.3161357 C19.0248052,13.7088535 19.0239742,14.3377297 18.6378937,14.7294286 L10.5178937,22.9675963 L9.08210625,21.5524037 L16.506,14.0196 L9.08023876,6.44569665 L10.5197612,5.03430335 Z"
              fill="#6266DB"
              fillRule="nonzero"
              id="路径-7"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
