import { GetComponentStyleFunc } from './types';

export const muiPopper: GetComponentStyleFunc<'MuiPopper'> = () => {
  return {
    styleOverrides: {
      root: {
        '& .MuiTooltip-tooltip': {
          fontWeight: 900,
          background: 'white',
          color: 'rgb(83,81,155)',
          boxShadow: '0px 0px 4px -1px #aaa4, 0px 4px 5px 0px #aaa4, 0px 1px 10px 0px #aaa4',
        },
      },
    },
  };
};
