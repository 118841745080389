import { Link, LinkProps } from '@mui/material';
import { FC } from 'react';

export const IconLink: FC<LinkProps & { href: string }> = ({ children, href }) => (
  <Link
    href={href}
    rel="noopener noreferrer"
    sx={{
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      borderRadius: '50%',
      background: theme => theme.colors.functional.container.lint,
      height: '24px',
      width: '24px',
    }}
    target="_blank"
  >
    {children}
  </Link>
);
