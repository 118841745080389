import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function IconLogoSimple(props: SvgIconProps) {
  return (
    <SvgIcon height="34" viewBox="0 0 34 34" width="34" {...props}>
      <g id="&#231;&#188;&#150;&#231;&#187;&#132; 2&#229;&#164;&#135;&#228;&#187;&#189; 14">
        <path
          clipRule="evenodd"
          d="M1.07637 10.4125C0.707617 10.7071 0.707617 11.2675 1.07637 11.5625L2.98738 13.091C3.25791 13.3077 3.64274 13.3062 3.91178 13.0878L14.8127 4.23062C14.9847 4.09074 15.0847 3.88092 15.0847 3.65919V0.737763C15.0847 0.120204 14.3704 -0.222951 13.8883 0.162763L1.07637 10.4125ZM4.36803 19.3437C4.09899 19.5622 3.71416 19.5637 3.44363 19.347L1.68768 17.9422C1.32101 17.6488 1.31893 17.0919 1.68321 16.7958L13.8838 6.8827C14.3651 6.49163 15.0847 6.83419 15.0847 7.45413V10.2857C15.0847 10.5074 14.9847 10.7175 14.8127 10.8574L4.36803 19.3437ZM2.13975 23.0517C1.77547 23.3479 1.77755 23.9047 2.14422 24.1982L13.8886 33.5937C14.3707 33.9794 15.085 33.636 15.085 33.0187V14.0809C15.085 13.461 14.3653 13.1184 13.8841 13.5095L2.13975 23.0517ZM16.5573 32.7732C16.5573 33.4291 17.3505 33.7577 17.8145 33.2937L33.7091 17.3988C33.9969 17.1113 33.9969 16.6449 33.7091 16.3574L17.8145 0.462763C17.3505 -0.000927003 16.5573 0.327347 16.5573 0.983597V32.7732Z"
          fill="#202723"
          fillRule="evenodd"
          id="&#229;&#189;&#162;&#231;&#138;&#182;"
        />
      </g>
    </SvgIcon>
  );
}
