import { LoadingButton } from '@mui/lab';
import { Box, Button, ButtonProps, FormControl, Stack, Typography } from '@mui/material';
import ConfirmationDialog from 'components/ConfirmationDialog';
import IconArrowDownFilled from 'components/icons/IconArrowDownFilled';
import IconInfo from 'components/icons/IconInfo';
import IconWarning from 'components/icons/IconWarning';
import MaxButton from 'components/MaxButton';
import MaximizableAmount from 'components/MaximizableAmount';
import RibbonStepper from 'components/RibbonStepper';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import { useStakingInfo } from 'contexts/StakingInfoContext';
import { useTxStatus } from 'contexts/TxStatusContext';
import REALT_VAULT_ABI from 'core/contracts/REALT_VAULT_ABI';
import STAKE_ABI from 'core/contracts/STAKE_ABI';
import { commify, formatWithPrecision, msToDaysHrsMinsSeconds } from 'core/utils';
import { useAmount } from 'hooks/useAmount';
import { useComputedStakingValues } from 'hooks/useComputedStakingValues';
import { useIsWrongNetwork } from 'hooks/useIsWrongNetwork';
import { useWriteTx } from 'hooks/useWriteTx';
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { formatUnits } from 'viem';
import { useAccount } from 'wagmi';

const reAltAddr = window.appConfig?.erc4626Vault;
const stAltConfig = window.appConfig?.vaults?.mach_alpha;
const stAltAddr = stAltConfig?.stakingContracts?.[0];

const StepUnstakeReALT: FC<{
  setActiveStep: Dispatch<SetStateAction<number>>;
}> = ({ setActiveStep }) => {
  const { refetchNodeStakedBalance, stakingInfo } = useStakingInfo();
  const stAltOperatorAddr = stakingInfo?.mach_alpha?.[stAltAddr]?.operatorAddresses?.[0];
  const { pendingTxs } = useTxStatus();

  const { fetchTokenBalance, reAltPerStAltRate, tokens } = useStakedTokens();

  const { amountRaw: stAltAmtRaw, setAmountRaw: setStAltAmtRaw } = useAmount(
    tokens?.[stAltAddr]?.decimals || 18
  );

  const {
    amountBN: reAltAmtBN,
    amountRaw: reAltAmtRaw,
    setAmountRaw: setReAltAmtRaw,
  } = useAmount(tokens?.[reAltAddr]?.decimals || 18);

  const { address } = useAccount();
  const redeemTxKey = `redeem_${reAltAddr}`;
  const isRedeeming = pendingTxs?.has(redeemTxKey);
  const { write: redeemReAlt } = useWriteTx({
    contractAbi: REALT_VAULT_ABI,
    contractAddress: reAltAddr,
    functionName: 'redeem',
    functionArgs: [reAltAmtBN, address, address],
    txKey: redeemTxKey,
    onTxConfirmed: () => {
      fetchTokenBalance(reAltAddr);
      fetchTokenBalance(stAltAddr, stAltOperatorAddr);
      refetchNodeStakedBalance('mach_alpha', stAltAddr, stAltOperatorAddr);
      setActiveStep(1);
    },
  });

  return (
    <>
      <MaximizableAmount
        endAdornment={
          <FormControl sx={{ minWidth: { xs: 100, lg: 140 } }}>
            <Typography
              sx={{
                fontSize: { xs: 14, sm: 18 },
                color: theme => theme.colors.schema.primary,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                columnGap: 0.75,
              }}
            >
              <Box component="img" src="/icons/iconReAlt.svg" style={{ width: 26 }} />
              {tokens?.[reAltAddr]?.symbol}
            </Typography>
          </FormControl>
        }
        fullWidth
        setAmountRaw={(val: string) => {
          setReAltAmtRaw(val);

          if (reAltPerStAltRate) {
            setStAltAmtRaw(String(Number(val) / reAltPerStAltRate));
          }
        }}
        sx={{ bgcolor: '#e4eadb', mt: 2 }}
        tokenSymbol={tokens?.[reAltAddr]?.symbol}
        value={reAltAmtRaw}
      />
      <Stack direction="row" justifyContent="space-between" mt={2}>
        <Typography variant="subtitle1">
          BALANCE:&nbsp;
          <Typography component="span" variant="subtitle1">
            {commify(tokens?.[reAltAddr]?.formattedBalance) || '-'}
          </Typography>
          <Typography component="span" variant="subtitle1">
            &nbsp;{tokens?.[reAltAddr]?.symbol}
          </Typography>
        </Typography>
        <MaxButton
          onClick={() => {
            const stringVal = formatUnits(
              tokens?.[reAltAddr]?.balance,
              tokens?.[reAltAddr]?.decimals
            );

            setReAltAmtRaw(stringVal);

            if (reAltPerStAltRate) {
              setStAltAmtRaw(String(Number(stringVal) / reAltPerStAltRate));
            }
          }}
        />
      </Stack>
      <Stack my={2}>
        <IconArrowDownFilled sx={{ mx: 'auto', width: 38, height: 38 }} />
      </Stack>
      <MaximizableAmount
        endAdornment={
          <FormControl sx={{ minWidth: { xs: 100, lg: 140 } }}>
            <Typography
              sx={{
                fontSize: { xs: 14, sm: 18 },
                color: theme => theme.colors.schema.primary,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                columnGap: 0.75,
              }}
            >
              <Box component="img" src="/icons/iconAltLayer.svg" style={{ width: 26 }} />
              stALT
            </Typography>
          </FormControl>
        }
        fullWidth
        setAmountRaw={(val: string) => {
          setStAltAmtRaw(val);
          setReAltAmtRaw(String(reAltPerStAltRate * Number(val)));
        }}
        tokenSymbol={tokens?.[stAltAddr]?.symbol}
        value={stAltAmtRaw}
      />
      <Typography mt={{ md: 1.25, xs: 1 }} variant="subtitle1">
        BALANCE:&nbsp;
        <Typography component="span" variant="subtitle1">
          {commify(tokens?.[stAltAddr]?.formattedBalance) || '-'}
        </Typography>
        <Typography component="span" variant="subtitle1">
          &nbsp;stALT
        </Typography>
      </Typography>
      <Typography
        fontSize={14}
        my={3}
        sx={{ background: 'linear-gradient(154.71deg, #FFFFFF 0%, #F7F7F7 100%)', py: 1 }}
        textAlign="center"
      >
        1 {tokens?.[stAltAddr]?.symbol} ≈ {reAltPerStAltRate} {tokens?.[reAltAddr]?.symbol}
      </Typography>
      <LoadingButton
        disabled={!reAltAmtBN || tokens?.[reAltAddr]?.balance < reAltAmtBN}
        fullWidth
        loading={isRedeeming}
        onClick={() => {
          redeemReAlt();
        }}
        variant="contained"
      >
        Convert
      </LoadingButton>
    </>
  );
};

const StepCooldown: FC<{
  onConfirmed?: () => void;
  setActiveStep: Dispatch<SetStateAction<number>>;
}> = ({ onConfirmed, setActiveStep }) => {
  const { refetchNodeCooldownInfo, refetchNodeStakedBalance, stakingInfo } = useStakingInfo();
  const { fetchTokenBalance } = useStakedTokens();

  const stAltOperatorAddr = stakingInfo?.mach_alpha?.[stAltAddr]?.operatorAddresses?.[0];

  const { amountBN, amountRaw, setAmountRaw } = useAmount(18);
  const { nodeBalances } = useComputedStakingValues();
  const stakingContractInfo = stakingInfo?.[stAltConfig?.id]?.[stAltAddr];
  const balances = Object.values(nodeBalances?.[stAltConfig?.id]?.[stAltAddr] || {})?.[0];
  const nodeStakedBalance = balances?.accruingRewards;
  const tokenSymbol = stakingContractInfo?.stakedTokenSymbol;
  const cooldownSeconds = stakingContractInfo?.cooldownSeconds;
  const timeArr = msToDaysHrsMinsSeconds((cooldownSeconds || 0) * 1000);
  const timeUnits = ['day', 'hour', 'minute', 'second'];
  const cooldownString =
    timeArr.reduce(
      (result, cur, index) =>
        cur > 0
          ? result + ' ' + String(cur) + ' ' + timeUnits[index] + `${cur > 1 ? 's' : ''}`
          : result,
      ''
    ) || '0s';

  const txKey = `cooldown_${stAltAddr}`;
  const { pendingTxs } = useTxStatus();
  const isLoading = pendingTxs?.has(txKey);
  const { write: beginCooldown } = useWriteTx({
    contractAbi: STAKE_ABI,
    contractAddress: stAltAddr,
    functionName: 'cooldownToUnstake',
    functionArgs: [stAltOperatorAddr, amountBN],
    onTxConfirmed: () => {
      onConfirmed?.();
      fetchTokenBalance(stAltAddr, stAltOperatorAddr);
      refetchNodeStakedBalance(stAltConfig?.id, stAltAddr, stAltOperatorAddr);
      refetchNodeCooldownInfo(stAltConfig?.id, stAltAddr, stAltOperatorAddr);
    },
    txKey,
  });

  useEffect(() => {
    setAmountRaw(formatUnits(nodeStakedBalance || 0n, 18));
  }, [nodeStakedBalance, setAmountRaw]);

  return (
    <>
      <MaximizableAmount
        endAdornment={
          <FormControl sx={{ minWidth: { xs: 100, lg: 140 } }}>
            <Typography
              sx={{
                fontSize: { xs: 14, sm: 18 },
                color: theme => theme.colors.schema.primary,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                columnGap: 0.75,
              }}
            >
              <Box component="img" src="/icons/iconAltLayer.svg" style={{ width: 26 }} />
              stALT
            </Typography>
          </FormControl>
        }
        fullWidth
        setAmountRaw={setAmountRaw}
        sx={{ mt: 2 }}
        value={amountRaw}
      />
      <Stack direction="row" justifyContent="space-between" mt={2}>
        <Typography variant="subtitle1">
          BALANCE:&nbsp;
          <Typography component="span" variant="subtitle1">
            {commify(formatWithPrecision(nodeStakedBalance || 0n)) || '-'}
          </Typography>
          <Typography component="span" variant="subtitle1">
            &nbsp;stALT
          </Typography>
        </Typography>
        <MaxButton
          onClick={() => {
            setAmountRaw(formatUnits(nodeStakedBalance || 0n, 18));
          }}
        />
      </Stack>
      <Stack alignItems="center" direction="row" gap={1} mt={{ md: 3, xs: 1.5 }}>
        <IconInfo sx={{ width: 20 }} />
        <Typography fontSize={14}>
          Tokens have to enter a {cooldownString} cooldown period before they are unstaked.
        </Typography>
      </Stack>
      <Stack alignItems="center" direction="row" gap={1} mt={{ md: 3, xs: 1.5 }}>
        <IconInfo sx={{ width: 20 }} />
        <Typography fontSize={14}>Rewards will not accrue on this token amount.</Typography>
      </Stack>
      {Boolean(balances?.inCooldown + balances?.withdrawable) && (
        <Typography
          mt={{ md: 1.25, xs: 1 }}
          sx={{
            bgcolor: '#F5F5DA',
            p: 2,
            mt: 2,
            fontSize: 12,
            wordBreak: 'break-word',
          }}
          variant="subtitle1"
          whiteSpace="wrap"
        >
          <IconWarning sx={{ stroke: 'red', fill: 'none', width: 20, height: 20, mb: -0.8 }} /> You
          have pending/finished cooldowns on{' '}
          <Typography component="span" sx={{ fontSize: 12, textDecoration: 'underline' }}>
            {formatWithPrecision(balances?.inCooldown + balances?.withdrawable)} {tokenSymbol}
          </Typography>
          . Proceeding will reset the cooldown period on this amount.
        </Typography>
      )}
      <LoadingButton
        disabled={amountBN <= 0 || amountBN > (nodeStakedBalance || 0n)}
        fullWidth
        loading={isLoading}
        onClick={() => {
          beginCooldown();
        }}
        sx={{ mt: 5 }}
        variant="contained"
      >
        Cooldown
      </LoadingButton>
      <Button
        autoFocus
        fullWidth
        onClick={() => {
          setActiveStep(0);
        }}
        sx={{ mt: 1 }}
        variant="outlined"
      >
        Back
      </Button>
    </>
  );
};

const stepLabels = ['Convert', 'Cooldown'];

export default function UnstakeReALTButton({ sx, ...rest }: ButtonProps) {
  const [activeStep, setActiveStep] = useState(0);
  const { tokens } = useStakedTokens();
  const balance = tokens?.[reAltAddr]?.balance;

  const isWrongNetwork = useIsWrongNetwork();
  const [showCooldownDialog, setShowCooldownDialog] = useState(false);

  const cooldownTxKey = `cooldown_${stAltAddr}`;
  const redeemTxKey = `redeem_${reAltAddr}`;
  const { pendingTxs } = useTxStatus();
  const isLoading = pendingTxs?.has(cooldownTxKey) || pendingTxs?.has(redeemTxKey);

  const steps = useMemo(
    () => [
      {
        content: <StepUnstakeReALT setActiveStep={setActiveStep} />,
      },
      {
        content: (
          <StepCooldown
            onConfirmed={() => {
              setShowCooldownDialog(false);
            }}
            setActiveStep={setActiveStep}
          />
        ),
      },
    ],
    [setActiveStep, setShowCooldownDialog]
  );

  return (
    <>
      <ConfirmationDialog
        handleClose={() => {
          setShowCooldownDialog(false);
        }}
        open={showCooldownDialog}
        renderButtons={() => <></>}
        sx={{
          '& .MuiDialog-paper': {
            minWidth: { xl: '30%', lg: '40%', md: '50%', xs: '60%' },
          },
        }}
        title={null}
      >
        <RibbonStepper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          stepLabels={stepLabels}
        />
        {steps[activeStep]?.content}
      </ConfirmationDialog>
      <LoadingButton
        disabled={!balance || isWrongNetwork}
        loading={isLoading}
        onClick={() => {
          setShowCooldownDialog(true);
          setActiveStep(0);
        }}
        sx={sx}
        variant="outlined"
        {...rest}
      >
        Unstake
      </LoadingButton>
    </>
  );
}
