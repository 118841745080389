import { GetComponentStyleFunc } from './types';

export const muiTypography: GetComponentStyleFunc<'MuiTypography'> = theme => {
  return {
    defaultProps: {
      variant: 'body1',
      variantMapping: {
        h1: 'h1',
        h2: 'div',
        h3: 'div',
        h4: 'div',
        h5: 'div',
        h6: 'div',
        subtitle1: 'div',
        subtitle2: 'div',
        body1: 'div',
        body2: 'div',
      },
    },
    styleOverrides: {
      paragraph: {},
      caption: {},
      button: {},
      inherit: {},
      overline: {},
      h1: {
        fontSize: '24px',
        lineHeight: '36px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '28px',
          lineHeight: '52px',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '40px',
          lineHeight: '60px',
        },
      },
      h2: {
        color: theme.colors.functional.text.primary,
        fontSize: '20px',
        lineHeight: '34px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '24px',
          lineHeight: '34px',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '26px',
          lineHeight: '36px',
        },
      },
      h3: {
        fontSize: '18px',
        lineHeight: '26px',
        fontWeight: 400,
        [theme.breakpoints.up('sm')]: {
          fontSize: '18px',
          lineHeight: '26px',
          fontWeight: 400,
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '18px',
          lineHeight: '26px',
          fontWeight: 400,
        },
      },
      h4: {
        '&': {
          fontSize: '20px',
          fontWeight: 400,
          lineHeight: '30px',
        },
      },
      h5: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '28px',
        color: theme.colors.functional.text.primary,
        [theme.breakpoints.up('md')]: {
          fontSize: '18px',
          lineHeight: '28px',
        },
      },
      h6: {
        '&': {
          fontSize: '16px',
          lineHeight: '18px',
          fontWeight: 400,
        },
      },
      subtitle1: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
      },
      subtitle2: {
        color: theme.colors.functional.text.lint,
        fontSize: '12px',
        lineHeight: '16px',
      },
      body1: {
        color: theme.colors.functional.text.primary,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
      },
      body2: {
        color: theme.colors.functional.text.primary,
        fontWeight: 900,
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  };
};
