import { Box, Stack, StackProps, Typography } from '@mui/material';
import { Dispatch, FC, Fragment, SetStateAction } from 'react';

const RibbonStepper: FC<
  StackProps & {
    activeStep: number;
    setActiveStep: Dispatch<SetStateAction<number>>;
    stepLabels: string[];
  }
> = ({ activeStep, setActiveStep, stepLabels, sx, ...rest }) => {
  const handleClickStep = (stepIndex: number) => () => {
    setActiveStep(stepIndex);
  };

  return (
    <Stack
      direction="row"
      p={0.5}
      sx={{
        height: '4rem',
        background: 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)',
        mb: 2,
        ...sx,
      }}
      width="100%"
      {...rest}
    >
      {stepLabels.map((stepLabel, index) => (
        <Fragment key={stepLabel}>
          {index > 0 && (
            <Box
              sx={{
                position: 'relative',
                right: '-0.5rem',
                content: '" "',
                borderTop: `1.8rem solid ${activeStep === index ? '#F3FAE5' : 'transparent'}`,
                borderBottom: `1.8rem solid ${activeStep === index ? '#F3FAE5' : 'transparent'}`,
                borderRight: `1rem solid ${activeStep === index ? '#F3FAE5' : 'transparent'}`,
                borderLeft: `1rem solid ${activeStep !== index ? '#F3FAE5' : 'transparent'}`,
                '&:before': {
                  content: '" "',
                  width: '0.5rem',
                  position: 'absolute',
                  left: '-1.5rem',
                  top: '-1.75rem',
                  height: '3.5rem',
                  bgcolor: `${activeStep !== index ? '#F3FAE5' : 'transparent'}`,
                  // bgcolor: 'black',
                },
              }}
            />
          )}
          <Box
            justifyContent="center"
            onClick={handleClickStep(index)}
            p={1}
            sx={{
              flex: 1,
              cursor: 'pointer',
              position: 'relative',
              bgcolor: index === activeStep ? ' #F3FAE5' : 'transparent',
            }}
          >
            <Typography
              mt={0.3}
              sx={{
                cursor: 'pointer',
                opacity: activeStep === index ? 1 : 0.4,
              }}
              textAlign="center"
              variant="h2"
              width="100%"
            >
              {stepLabel}
            </Typography>
          </Box>
        </Fragment>
      ))}
    </Stack>
  );
};

export default RibbonStepper;
