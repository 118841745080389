import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconArrowRight({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      fill="none"
      height="12"
      sx={{ color: '#202723', ...sx }}
      viewBox="0 0 11 12"
      width="11"
      {...props}
    >
      <path
        d="M0.00292969 5.95085H9.90242M9.90242 5.95085L4.95268 1.0011M9.90242 5.95085L4.95268 10.9006"
        stroke="currentColor"
        strokeWidth="1.4"
      />
    </SvgIcon>
  );
}
