import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconCaretDown({ ...props }: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" id="页面-1" stroke="none" strokeWidth="1">
        <g id="Multi-stake1" transform="translate(-790, -294)">
          <g id="down" transform="translate(790, 294)">
            <rect height="24" id="_x3C_Transparent_Rectangle_x3E_" width="24" x="0" y="0"></rect>
            <path
              d="M19.590648,9.05250067 L12.5829438,15.9232699 C12.2506441,16.2490763 11.7185181,16.2483731 11.3870805,15.9216897 L4.41632322,9.05092048 L5.61379393,7.83602337 L11.9877047,14.1177796 L18.3963922,7.83444319 L19.590648,9.05250067 Z"
              fill="#6266DB"
              fillRule="nonzero"
              id="路径"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
