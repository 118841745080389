import { Box, Button, Drawer, Grid, Stack, StackProps, Typography, useTheme } from '@mui/material';
import { IconLink } from 'components/IconLink';
import IconArrowForward from 'components/icons/IconArrowForward';
import IconCaretDown from 'components/icons/IconCaretDown';
import IconTwitter from 'components/icons/IconTwitter';
import IconWebsite from 'components/icons/IconWebsite';
import { LaunchPoolLabel, LiveLabel } from 'components/VaultLabels';
import { useStakingInfo } from 'contexts/StakingInfoContext';
import { useComputedStakingValues } from 'hooks/useComputedStakingValues';
import { useLastSelectedVault } from 'hooks/useLastSelectedVault';
import { useState } from 'react';
import { VaultConfig } from 'types';
import { Address } from 'viem';

const vaultsConfigs = window.appConfig?.vaults;
const erc20Info = window.appConfig?.erc20Info;
const launchPoolTokens = window.appConfig?.launchPoolTokens;

export default function VaultSelectDrawer({
  arrowDirection = 'right',
  sx,
  vaults = Object.values(vaultsConfigs || {}),
  ...props
}: {
  arrowDirection?: 'down' | 'right';
  vaults?: VaultConfig[];
} & StackProps) {
  const { stakingInfo } = useStakingInfo();
  const { tokensByVault } = useComputedStakingValues();

  const vaultIds = Object.keys(stakingInfo || {});

  const { lastSelectedVaultId, setLastSelectedVaultId } = useLastSelectedVault();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const [selectedVaultId, setSelectedVaultId] = useState(lastSelectedVaultId);

  return (
    <>
      <Stack
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        onClick={() => {
          if (vaultIds?.length > 1) {
            setIsDrawerOpen(true);
          }
        }}
        sx={{
          px: 2,
          cursor: 'pointer',
          height: '60px',
          background: theme.colors.functional.container.first,
          color: theme.colors.functional.container.button,
          borderRadius: '0.5rem',
          ...sx,
        }}
        {...props}
      >
        <Typography sx={{ fontWeight: 900 }} variant="h6">
          {lastSelectedVaultId ? vaultsConfigs?.[lastSelectedVaultId]?.name : 'Select a Vault'}
        </Typography>

        <Stack alignItems="center" direction="row" gap={2}>
          {!lastSelectedVaultId ? (
            ''
          ) : launchPoolTokens?.[lastSelectedVaultId] ? (
            <LaunchPoolLabel />
          ) : (
            <LiveLabel />
          )}
          {arrowDirection === 'right' ? <IconArrowForward /> : <IconCaretDown />}
        </Stack>
      </Stack>

      <Drawer
        anchor="bottom"
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        open={isDrawerOpen}
        sx={{
          '& .MuiPaper-root': {
            top: '92px',
            background: theme.colors.functional.container.default,
            boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.12)',
            borderRadius: '40px 40px 0px 0px',
          },
        }}
      >
        <Box minHeight="300px" p={{ md: 5, xs: 2.5 }} width="100%">
          <Stack alignItems="center" flexDirection="row" justifyContent="space-between" mb={2.5}>
            <Typography variant="h2">Select a Vault</Typography>
            <Stack flexDirection="row">
              <Button
                onClick={() => {
                  setIsDrawerOpen(false);
                }}
                sx={{
                  mr: { md: 2, xs: 1 },
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setIsDrawerOpen(false);

                  if (selectedVaultId !== undefined) {
                    setLastSelectedVaultId(selectedVaultId);
                  }
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </Stack>
          </Stack>
          <Grid
            alignItems="center"
            container
            mb={{
              md: 2.5,
              xs: 1.25,
            }}
            px={{ lg: 3, xs: 0 }}
          >
            <Grid item lg={6} xs={3}>
              <Typography color={theme.colors.functional.subject.third}>Vault</Typography>
            </Grid>
            <Grid item lg={2} textAlign="right" xs={3}>
              <Typography color={theme.colors.functional.subject.third}>Stake</Typography>
            </Grid>
            <Grid item lg={2} textAlign="right" xs={3}>
              <Typography color={theme.colors.functional.subject.third}>Earn</Typography>
            </Grid>
            <Grid item lg={2} textAlign="right" xs={3}>
              <Typography color={theme.colors.functional.subject.third}>Status</Typography>
            </Grid>
          </Grid>
          {vaults?.map(vault => (
            <VaultDetail
              key={vault.id}
              onChoose={() => setSelectedVaultId(vault.id)}
              selected={selectedVaultId === vault.id}
              tokens={tokensByVault?.[vault.id]}
              {...vault}
            />
          ))}
        </Box>
      </Drawer>
    </>
  );
}

function VaultDetail({
  desc,
  id: vaultId,
  name,
  onChoose,
  selected,
  tokens,
  twitter,
  website,
}: VaultConfig & {
  onChoose: () => void;
  selected: boolean;
  tokens: { stakedTokens: string[]; rewardTokens: string[] };
}) {
  const theme = useTheme();

  return (
    <Box
      onClick={onChoose}
      sx={{
        mb: 1.25,
        '&:last-child': {
          mb: 0,
        },
        p: { md: 3, xs: 1.5 },
        background: selected ? '#f0f0fe' : '#F6F6F6',
        boxShadow: '#e7e7e7 0px 2px 6px',
        border: selected ? `2px solid ${theme.colors.functional.container.button}` : 'none',
        cursor: 'pointer',
      }}
    >
      <Grid alignItems="center" container flexDirection="row" justifyContent="space-between">
        <Grid item lg={6} xs={3}>
          <Stack alignItems="center" flexDirection="row">
            <Typography
              mr={{
                md: 2.5,
                xs: 1.25,
              }}
              variant="h4"
            >
              {name}
            </Typography>
            {selected && (
              <>
                <IconLink href={twitter} sx={{ mr: 0.5 }}>
                  <IconTwitter
                    fillColor={theme.colors.functional.container.button}
                    sx={{
                      height: '16px',
                      width: '16px',
                    }}
                  />
                </IconLink>
                <IconLink href={website}>
                  <IconWebsite
                    sx={{
                      height: '16px',
                      width: '16px',
                    }}
                  />
                </IconLink>
              </>
            )}
          </Stack>
        </Grid>
        <Grid item lg={2} textAlign="right" xs={3}>
          <Typography variant="h4">
            {tokens?.stakedTokens?.map(addr => (
              <Box
                component="img"
                key={addr}
                src={erc20Info?.[addr as Address]?.iconUrl}
                sx={{ width: 30, marginRight: 0.5 }}
              />
            ))}
          </Typography>
        </Grid>
        <Grid item lg={2} textAlign="right" xs={3}>
          {tokens?.rewardTokens?.length
            ? tokens?.rewardTokens?.map(addr => (
                <Box
                  component="img"
                  key={addr}
                  src={erc20Info?.[addr as Address]?.iconUrl}
                  sx={{ width: 30, marginRight: 0.5 }}
                />
              ))
            : window.appConfig?.launchPoolTokens?.[vaultId]?.map(token => (
                <Box
                  component="img"
                  key={token?.tokenName}
                  src={token?.iconUrl}
                  sx={{ width: 30, marginRight: 0.5 }}
                />
              ))}
        </Grid>
        <Grid container item justifyContent="flex-end" lg={2} xs={3}>
          {launchPoolTokens?.[vaultId] ? <LaunchPoolLabel /> : <LiveLabel />}
        </Grid>
      </Grid>
      {selected && (
        <Typography
          color={theme.colors.functional.subject.third}
          mt={{ md: 2, xs: 1 }}
          variant="body1"
        >
          {desc}
        </Typography>
      )}
    </Box>
  );
}
