import { Typography, useTheme } from '@mui/material';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { commify } from 'core/utils';

interface FormBalanceProps extends OutlinedInputProps {
  tokenSymbol?: string;
  value: string;
  setAmountRaw: (value: string) => void;
}

export default function MaximizableAmount({
  endAdornment,
  setAmountRaw,
  sx,
  tokenSymbol,
  value,
  ...props
}: FormBalanceProps) {
  const theme = useTheme();
  let displayValue = value;

  if (value) {
    const [whole, fractional] = value.split('.', 2);
    const truncatedFractional = fractional?.substring(0, fractional?.length);
    const result = truncatedFractional
      ? [commify(whole), truncatedFractional].join('.')
      : commify(whole);

    if (value?.match(/^\d*\.$/)) {
      displayValue = result + '.';
    } else if (!value?.match(/^\d*\.{0,1}\d*$/)) {
      displayValue = result?.match(/^\d*\.{0,1}\d*$/) ? result : result?.slice(0, -1);
    } else {
      displayValue = result;
    }
  } else {
    displayValue = value;
  }

  return (
    <OutlinedInput
      endAdornment={
        endAdornment ||
        (tokenSymbol && (
          <Typography
            color={theme.colors.functional.subject.third}
            fontSize={{ xs: 14, sm: 18 }}
            ml={1}
            mr={2}
          >
            {tokenSymbol}
          </Typography>
        ))
      }
      inputProps={{
        onWheel: event => event.currentTarget.blur(),
        sx: { '&&': { fontSize: { xs: 14, md: 20, lg: 24 } } },
      }}
      onChange={e => {
        let val = e?.target?.value;

        val = val?.replaceAll(',', '');
        setAmountRaw(val);
      }}
      placeholder="0.00"
      sx={{
        pr: 0,
        flex: 1,
        fontWeight: 900,
        background: 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)',
        ...sx,
      }}
      type="text"
      value={displayValue}
      {...props}
    />
  );
}
