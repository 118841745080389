import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconChecked({ ...props }: SvgIconProps) {
  return (
    <SvgIcon height="20px" viewBox="0 0 20 20" width="20px" {...props}>
      <g fill="none" fillRule="evenodd" id="页面-1" stroke="none" strokeWidth="1">
        <g id="Multi-stake2" transform="translate(-510, -681)">
          <g id="checkmark" transform="translate(510, 681)">
            <rect height="20" id="_Transparent_Rectangle_" width="20" x="0" y="0"></rect>
            <polygon
              fill="currentColor"
              fillRule="nonzero"
              id="路径"
              points="2.5 9.375 3.70407468 8.09721916 8.125 12.5175617 16.2803288 4.36283313 17.5 5.625 8.125 15"
            ></polygon>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
