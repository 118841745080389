import { Button, ButtonProps, Typography } from '@mui/material';
import { FC } from 'react';

const MaxButton: FC<ButtonProps> = ({ onClick, sx, ...rest }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        color: '#202723',
        pb: 0.25,
        pt: 0.5,
        px: 1,
        minWidth: 'unset',
        ...sx,
      }}
      variant="text"
      {...rest}
    >
      <Typography variant="h6">MAX</Typography>
    </Button>
  );
};

export default MaxButton;
