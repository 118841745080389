import { useLocalStorage } from './useLocalStorage';

export function useLastSelectedVault() {
  const [lastSelectedVaultId, setLastSelectedVaultId] = useLocalStorage<string>(
    'avs-staking.prev-vault.id',
    Object.values(window.appConfig?.vaults || {})?.[0]?.id
  );

  return {
    lastSelectedVaultId,
    setLastSelectedVaultId,
  };
}
