import { Box, Link as MuiLink } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { ConnectWithTermsButton } from 'components/CustomConnectButton';
import IconLogoSimple from 'components/icons/IconLogoSimple';
import { Link } from 'react-router-dom';
import { useAccount } from 'wagmi';

import StkALTDisplay from './StkALTDisplay';

const Header: React.FC = () => {
  const { isConnected } = useAccount();

  return (
    <Stack
      sx={{
        alignItems: 'center',
        flexDirection: 'row',
        background: 'white',
        height: '80px',
        margin: '0px auto',
        boxShadow: '0px 1px 0px 0px rgba(0,0,0,0.12)',
        justifyContent: 'space-between',
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 10,
        py: 0,
      }}
    >
      <Stack
        sx={{
          flex: 1,
          height: '100%',
          background: 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)',
        }}
      >
        <MuiLink
          className="hover-grow"
          component={Link}
          sx={{ mt: 1.5, ml: { xs: 1, sm: 2 }, width: { xs: 60, sm: 80 } }}
          to="/"
        >
          <IconLogoSimple
            sx={{
              mt: { xs: 2, sm: 1 },
              ml: 1.5,
              display: { xs: 'block', md: 'none' },
              width: { xs: 30, sm: 40 },
              height: { xs: 30, sm: 40 },
            }}
          />
          <Box
            component="img"
            src="/imgs/altLayerLogo.png"
            sx={{
              p: '0.3rem',
              ml: { xl: 2 },
              width: { xs: '10rem', md: '14rem' },
              height: 'auto',
              display: { xs: 'none', md: 'block' },
            }}
          />
        </MuiLink>
      </Stack>
      <Stack
        alignItems="center"
        columnGap={2}
        direction="row"
        justifyContent="center"
        sx={{
          background: 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)',
          height: '100%',
          p: { xs: 1, md: 2, xl: 4 },
        }}
      >
        <StkALTDisplay />
      </Stack>
      <Stack
        alignItems="center"
        columnGap={2}
        direction="row"
        justifyContent="center"
        sx={{
          background: 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)',
          height: '100%',
          p: { xs: 1, md: 2, xl: 4 },
        }}
      >
        {isConnected ? <ConnectButton /> : <ConnectWithTermsButton />}
      </Stack>
    </Stack>
  );
};

export default Header;
