import { useStakedTokens } from 'contexts/StakedTokensContext';
import { Cooldown, useStakingInfo } from 'contexts/StakingInfoContext';
import { formatWithPrecision } from 'core/utils';
import { useEffect, useMemo } from 'react';
import { Address } from 'viem';

export interface NodeBalance {
  withdrawable: bigint;
  accruingRewards: bigint;
  inCooldown: bigint;
}

export function useComputedStakingValues() {
  const { stakingInfo } = useStakingInfo();
  const { prices } = useStakedTokens();

  const votingStakeTotalsByContract = useMemo(() => {
    return Object.keys(stakingInfo)?.reduce((totalVotingStake, vaultId) => {
      const vaultVotingStake = Object.values(stakingInfo?.[vaultId] || {})?.reduce(
        (_vaultVotingStake, contractInfo) => {
          const contractVotingStake = Object.values(contractInfo.votingStakeBalances || {})?.reduce(
            (_contractVotingStake, balance) => balance + _contractVotingStake,
            0n
          );

          return {
            ..._vaultVotingStake,
            [contractInfo?.stakingContractAddr]: {
              isLaunchPool: Boolean(window.appConfig?.launchPoolTokens?.[vaultId]),
              user:
                contractVotingStake +
                (_vaultVotingStake?.[contractInfo?.stakedTokenAddr as Address]?.user || 0n),
              global:
                (contractInfo?.globalVotingStake || 0n) +
                (_vaultVotingStake?.[contractInfo?.stakedTokenAddr as Address]?.global || 0n),
            },
          };
        },
        {} as Record<Address, { user: bigint; global: bigint; isLaunchPool: boolean }>
      );

      return {
        ...totalVotingStake,
        ...vaultVotingStake,
      };
    }, {} as Record<Address, { user: bigint; global: bigint; isLaunchPool: boolean }>);
  }, [stakingInfo]);

  const contractsEmissionsPerSec = useMemo(() => {
    return Object.values(stakingInfo)?.reduce((_vaultEmissions, vaultContracts) => {
      const contractEmissions = Object.values(vaultContracts || {})?.reduce(
        (_contractEmissions, stakingContract) => {
          const distEmissions = Object.values(stakingContract?.distributionMap || {})?.reduce(
            (_distEmissions, dist) => ({
              ..._distEmissions,
              [dist?.rewardToken]: (_distEmissions?.[dist?.rewardToken] || 0n) + dist.eps,
            }),
            {} as Record<Address, bigint>
          );

          return {
            ..._contractEmissions,
            [stakingContract?.stakingContractAddr]: Object.entries(distEmissions || {})?.reduce(
              (sumMap, [rewardToken, amount]) => ({
                ...sumMap,
                [rewardToken]:
                  amount +
                  (_contractEmissions?.[stakingContract?.stakingContractAddr]?.[
                    rewardToken as Address
                  ] || 0n),
              }),
              {} as Record<Address, Record<Address, bigint>>
            ),
          };
        },
        {} as Record<Address, Record<Address, bigint>>
      );

      return {
        ..._vaultEmissions,
        ...Object.entries(contractEmissions || {})?.reduce(
          (sumMap, [stakingContractAddr, tokenMap]) => ({
            ...sumMap,
            [stakingContractAddr]: tokenMap,
          }),
          {} as Record<Address, Record<Address, bigint>>
        ),
      };
    }, {} as Record<Address, Record<Address, bigint>>);
  }, [stakingInfo]);

  const { nodeBalances, totalAccruingRewards, totalAvailableWithdrawals, totalInCooldown } =
    useMemo(() => {
      return Object.keys(stakingInfo || {})?.reduce(
        (appTotals, vaultId) => {
          const vaultStakedTokens = (
            Object.keys(stakingInfo?.[vaultId] || {}) as Address[]
          )?.reduce(
            (vaultTotals, contractAddr) => {
              const stakedTokenAddr = stakingInfo?.[vaultId]?.[contractAddr]
                ?.stakedTokenAddr as Address;
              const contractTotals = Object.keys(
                stakingInfo?.[vaultId]?.[contractAddr]?.cooldowns || {}
              )?.reduce(
                (contractTotals, operatorAddr) => {
                  const cooldown =
                    stakingInfo?.[vaultId]?.[contractAddr]?.cooldowns?.[operatorAddr] ||
                    ({} as Cooldown);
                  const votingStakeBalance =
                    stakingInfo?.[vaultId]?.[contractAddr]?.votingStakeBalances?.[operatorAddr] ||
                    0n;

                  if (!cooldown.timestamp) {
                    return {
                      ...contractTotals,
                      totalAccruingRewards:
                        contractTotals.totalAccruingRewards + votingStakeBalance,
                      balanceMap: {
                        ...contractTotals?.balanceMap,
                        [operatorAddr]: {
                          accruingRewards: votingStakeBalance,
                          inCooldown: 0n,
                          withdrawable: 0n,
                        },
                      },
                    };
                  }

                  if (
                    (Number(stakingInfo?.[vaultId]?.[contractAddr]?.cooldownSeconds) +
                      cooldown.timestamp) *
                      1000 <=
                    Date.now()
                  ) {
                    return {
                      ...contractTotals,
                      totalWithdrawable: contractTotals.totalWithdrawable + cooldown.amount,
                      totalAccruingRewards:
                        contractTotals.totalAccruingRewards + votingStakeBalance,
                      balanceMap: {
                        ...contractTotals?.balanceMap,
                        [operatorAddr]: {
                          accruingRewards: votingStakeBalance,
                          inCooldown: 0n,
                          withdrawable: cooldown.amount,
                        },
                      },
                    };
                  }

                  if (
                    (Number(stakingInfo?.[vaultId]?.[contractAddr]?.cooldownSeconds) +
                      cooldown.timestamp) *
                      1000 >
                    Date.now()
                  ) {
                    return {
                      ...contractTotals,
                      totalInCooldown: contractTotals.totalInCooldown + cooldown.amount,
                      totalAccruingRewards:
                        contractTotals.totalAccruingRewards + votingStakeBalance,
                      balanceMap: {
                        ...contractTotals?.balanceMap,
                        [operatorAddr]: {
                          accruingRewards: votingStakeBalance,
                          inCooldown: cooldown.amount,
                          withdrawable: 0n,
                        },
                      },
                    };
                  }

                  return contractTotals;
                },
                {
                  totalWithdrawable: 0n,
                  totalInCooldown: 0n,
                  totalAccruingRewards: 0n,
                  balanceMap: {} as Record<Address, NodeBalance>,
                }
              );

              return {
                totalAvailableWithdrawals: {
                  ...vaultTotals?.totalAvailableWithdrawals,
                  [stakedTokenAddr]:
                    contractTotals.totalWithdrawable +
                    (vaultTotals.totalAvailableWithdrawals?.[stakedTokenAddr] || 0n),
                },
                totalInCooldown: {
                  ...vaultTotals?.totalInCooldown,
                  [stakedTokenAddr]:
                    contractTotals.totalInCooldown +
                    (vaultTotals.totalAvailableWithdrawals?.[stakedTokenAddr] || 0n),
                },
                totalAccruingRewards: {
                  ...vaultTotals?.totalAccruingRewards,
                  [stakedTokenAddr]:
                    contractTotals.totalAccruingRewards +
                    (vaultTotals.totalAccruingRewards?.[stakedTokenAddr] || 0n),
                },
                nodeBalances: {
                  ...vaultTotals.nodeBalances,
                  [contractAddr]: contractTotals.balanceMap,
                },
              };
            },
            {
              totalAvailableWithdrawals: {} as Record<Address, bigint>,
              totalInCooldown: {} as Record<Address, bigint>,
              totalAccruingRewards: {} as Record<Address, bigint>,
              nodeBalances: {} as Record<Address, Record<Address, NodeBalance>>,
            }
          );

          return {
            ...appTotals,
            totalAvailableWithdrawals: {
              ...appTotals?.totalAvailableWithdrawals,
              ...(
                Object.keys(vaultStakedTokens?.totalAvailableWithdrawals || {}) as Address[]
              )?.reduce((acc, stakedTokenAddr) => {
                return {
                  ...acc,
                  [stakedTokenAddr]:
                    (appTotals?.totalAvailableWithdrawals?.[stakedTokenAddr] || 0n) +
                    (acc?.[stakedTokenAddr] || 0n) +
                    BigInt(vaultStakedTokens?.totalAvailableWithdrawals?.[stakedTokenAddr] || 0n),
                };
              }, {} as Record<Address, bigint>),
            },
            totalInCooldown: {
              ...appTotals?.totalInCooldown,
              ...(Object.keys(vaultStakedTokens?.totalInCooldown || {}) as Address[])?.reduce(
                (acc, stakedTokenAddr) => {
                  return {
                    ...acc,
                    [stakedTokenAddr]:
                      (appTotals?.totalInCooldown?.[stakedTokenAddr] || 0n) +
                      (acc?.[stakedTokenAddr] || 0n) +
                      BigInt(vaultStakedTokens?.totalInCooldown?.[stakedTokenAddr] || 0n),
                  };
                },
                {} as Record<Address, bigint>
              ),
            },
            totalAccruingRewards: {
              ...appTotals?.totalAccruingRewards,
              ...(Object.keys(vaultStakedTokens?.totalAccruingRewards || {}) as Address[])?.reduce(
                (acc, stakedTokenAddr) => {
                  return {
                    ...acc,
                    [stakedTokenAddr]:
                      (appTotals?.totalAccruingRewards?.[stakedTokenAddr] || 0n) +
                      (acc?.[stakedTokenAddr] || 0n) +
                      BigInt(vaultStakedTokens?.totalAccruingRewards?.[stakedTokenAddr] || 0n),
                  };
                },
                {} as Record<Address, bigint>
              ),
            },
            nodeBalances: {
              ...appTotals.nodeBalances,
              [vaultId]: vaultStakedTokens?.nodeBalances,
            },
          };
        },
        {
          totalAvailableWithdrawals: {} as Record<Address, bigint>,
          totalInCooldown: {} as Record<Address, bigint>,
          totalAccruingRewards: {} as Record<Address, bigint>,
          nodeBalances: {} as Record<string, Record<Address, Record<Address, NodeBalance>>>,
        }
      );
    }, [stakingInfo]);

  useEffect(() => {
    console.debug('nodeBalances: ', nodeBalances);
  }, [nodeBalances]);

  const tokenRewards = useMemo(
    () =>
      Object.values(stakingInfo)?.reduce((totalRewards, rewardsByContract) => {
        const totals = Object.values(rewardsByContract)?.reduce((totals, contractInfo) => {
          const contractRewards = Object.values(contractInfo.rewards || {})?.reduce(
            (contractTotals, balMap) => ({
              ...contractTotals,
              ...Object.entries(balMap || {}).reduce(
                (rewards, [addr, value]) => ({
                  ...rewards,
                  [addr]:
                    (contractTotals[addr as Address] || 0n) +
                    (rewards[addr as Address] || 0n) +
                    value,
                }),
                {} as Record<Address, bigint>
              ),
            }),
            {} as Record<Address, bigint>
          );

          return {
            ...totals,
            ...Object.entries(contractRewards || {}).reduce(
              (rewards, [addr, value]) => ({
                ...rewards,
                [addr]: (totals[addr as Address] || 0n) + value,
              }),
              {}
            ),
          };
        }, {} as Record<Address, bigint>);

        return {
          ...totalRewards,
          ...Object.entries(totals || {}).reduce(
            (rewards, [addr, value]) => ({
              ...rewards,
              [addr]: (totalRewards[addr as Address] || 0n) + value,
            }),
            {}
          ),
        };
      }, {} as Record<Address, bigint>),
    [stakingInfo]
  );

  const totalRewardsUsd = useMemo(() => {
    return Object.entries(tokenRewards || {})?.reduce(
      (sum, [stakedTokenAddr, amount]) =>
        sum + Number(formatWithPrecision(amount || 0n)) * prices?.[stakedTokenAddr as Address],
      0
    );
  }, [prices, tokenRewards]);

  const tokensByVault = useMemo(() => {
    return Object.entries(stakingInfo || {})?.reduce(
      (_tokensByVault, [vaultId, contractsStakingInfo]) => {
        return {
          ..._tokensByVault,
          [vaultId]: {
            stakedTokens: Array.from(
              Object.values(contractsStakingInfo)?.reduce(
                (_uniqueStakedTokens, stakingContractInfo) => {
                  const uniqueStakedTokens = new Set(_uniqueStakedTokens);

                  uniqueStakedTokens.add(stakingContractInfo?.stakedTokenAddr as Address);

                  return uniqueStakedTokens;
                },
                new Set<Address>()
              )
            ),
            rewardTokens: Array.from(
              Object.values(contractsStakingInfo)?.reduce(
                (_uniqueStakedTokens, stakingContractInfo) => {
                  const uniqueStakedTokens = new Set(_uniqueStakedTokens);

                  const contractRewards = Object.keys(
                    Object.values(stakingContractInfo?.rewards || {})?.[0] || {} // assume only 1 operator per vault
                  );

                  contractRewards?.forEach(addr => uniqueStakedTokens.add(addr as Address));

                  return uniqueStakedTokens;
                },
                new Set<Address>()
              )
            ),
          },
        };
      },
      {} as Record<string, { stakedTokens: string[]; rewardTokens: string[] }>
    );
  }, [stakingInfo]);

  return {
    votingStakeTotalsByContract,
    nodeBalances,
    totalAccruingRewards,
    totalAvailableWithdrawals,
    totalInCooldown,
    tokenRewards,
    totalRewardsUsd,
    contractsEmissionsPerSec,
    tokensByVault,
  };
}
