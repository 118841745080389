import { SvgIconProps, Typography } from '@mui/material';
import { paths } from 'App';
import IconPortfolio from 'components/icons/IconPortfolio';
import IconQuestionMark from 'components/icons/IconQuestionMark';
import IconRewards from 'components/icons/IconRewards';
import IconStake from 'components/icons/IconStake';
import IconVault from 'components/icons/IconVault';

export function useTabs() {
  const tabs = [
    {
      icon: IconStake,
      path: paths.Stake,
      title: 'Restake',
    },
    {
      icon: IconVault,
      path: paths.Vaults,
      title: 'Vaults',
    },
    {
      icon: IconPortfolio,
      path: paths.Portfolio,
      title: 'Portfolio',
    },
    {
      icon: IconRewards,
      path: paths.Rewards,
      title: 'Rewards',
    },
    {
      icon: ({ sx, ...rest }: SvgIconProps) => (
        <IconQuestionMark sx={{ p: 0.4, mr: { xs: 0.5, lg: 2 }, ...sx }} {...rest} />
      ),
      path: paths.FAQ,
      title: (
        <Typography sx={{ position: 'relative', left: -3, fontSize: 'unset' }}>FAQ</Typography>
      ),
    },
  ];

  return tabs;
}
