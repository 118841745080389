import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconQuestionMark({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      fill="none"
      sx={{ width: 28, height: 28, position: 'relative', ...sx }}
      viewBox="0 0 26 26"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M23 13C23 18.5228 18.5228 23 13 23C7.47715 23 3 18.5228 3 13C3 7.47715 7.47715 3 13 3C18.5228 3 23 7.47715 23 13ZM24 13C24 19.0751 19.0751 24 13 24C6.92487 24 2 19.0751 2 13C2 6.92487 6.92487 2 13 2C19.0751 2 24 6.92487 24 13ZM13.7 7.5H12.5C11.1193 7.5 10 8.61929 10 10C10 10.1712 10.0172 10.3384 10.05 10.5H9.03544C9.01209 10.3367 9 10.1698 9 10C9 8.067 10.567 6.5 12.5 6.5H13.7C15.633 6.5 17.2 8.067 17.2 10C17.2 11.933 15.633 13.5 13.7 13.5H13.5V15.5H12.5V12.5H13H13.5H13.7C15.0807 12.5 16.2 11.3807 16.2 10C16.2 8.61929 15.0807 7.5 13.7 7.5ZM13 19.5C13.5523 19.5 14 19.0523 14 18.5C14 17.9477 13.5523 17.5 13 17.5C12.4477 17.5 12 17.9477 12 18.5C12 19.0523 12.4477 19.5 13 19.5Z"
        fill="#202723"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
