import { Skeleton, Stack } from '@mui/material';

const PageSkeleton = () => (
  <Stack alignItems="center" flex={1} justifyContent="center">
    <Stack sx={{ bgcolor: '#fff7', m: 2, p: 3, width: '35rem', maxWidth: '80vw', gap: 0 }}>
      <Stack direction="row" justifyContent="space-between">
        <Skeleton sx={{ width: '49.5%', height: '6rem' }} variant="text" />
        <Skeleton sx={{ width: '49.5%', height: '6rem' }} variant="text" />
      </Stack>
      <Stack direction="row" height="3rem">
        <Skeleton sx={{ height: '4rem', width: '100%' }} variant="text" />
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Skeleton sx={{ fontSize: '2rem', width: '10rem' }} variant="text" />
        <Skeleton sx={{ fontSize: '2rem', width: '3rem' }} variant="text" />
      </Stack>
      <Skeleton height={150} sx={{ mt: 2 }} variant="rectangular" />
      <Skeleton sx={{ fontSize: '2rem' }} variant="text" />
      <Skeleton sx={{ fontSize: '3rem' }} variant="text" />
    </Stack>
  </Stack>
);

// const PageSkeleton = () => (
//   <Box
//     sx={{
//       display: 'grid',
//       gridTemplateColumns: 'repeat(29,1fr)',
//     }}
//   >
//     {new Array(540).fill(null).map((cur, index) => (
//       <Skeleton
//         // animation="wave"
//         height={'4vh'}
//         key={index}
//         sx={{
//           opacity: 0.4,
//           bgcolor: index % 2 === 0 ? '#EAF4DC' : '#D5E9BB',
//           // background:
//           //   index % 2 === 0
//           //     ? 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)'
//           //     : 'linear-gradient(127.48deg, #D5E9BB 0%, #EAF4DC 100%)',
//         }}
//         variant="rectangular"
//       />
//     ))}
//   </Box>
// );

export default PageSkeleton;
