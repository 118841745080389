import {
  Badge,
  Box,
  BoxProps,
  ClickAwayListener,
  Stack,
  SvgIconProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { paths } from 'App';
import { useComputedStakingValues } from 'hooks/useComputedStakingValues';
import { useTabs } from 'hooks/useTabs';
import React, { ReactNode, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const TabItem: React.FC<{
  tab: { icon: ({ ...props }: SvgIconProps) => JSX.Element; path: paths; title: ReactNode };
}> = ({ tab }) => {
  const location = useLocation();
  const shallowestPath = location.pathname?.split('/')?.[1];
  const isRestakingPath = tab.path === paths.Stake && shallowestPath === 'restake';
  const isActiveTab =
    shallowestPath !== '' && (tab.path?.includes(shallowestPath) || isRestakingPath);
  const { totalRewardsUsd } = useComputedStakingValues();

  const [open, setOpen] = useState(false);

  const openTooltip = () => {
    setOpen(true);
  };

  const closeTooltip = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={closeTooltip}>
      <Tooltip
        componentsProps={{ popper: { sx: { display: { sm: 'none' } } } }}
        disableHoverListener
        onClose={closeTooltip}
        open={open}
        title={tab.title}
      >
        <Stack
          component={Link}
          flexDirection="row"
          onClick={openTooltip}
          sx={{
            p: 2.5,
            alignItems: 'center',
            justifyContent: 'center',
            height: '60px',
            flex: 1,
            background: isActiveTab
              ? '#EAF4DC'
              : 'linear-gradient(127.08deg, #EAF4DC -26.35%, #D5E9BB 111.98%)',
            '& > a': {
              color: isActiveTab ? '#6266DB' : '#373741',
            },
            borderTop: '1px solid #CCE2B3DD',
            borderBottom: isActiveTab ? 'none' : '1px solid #CCE2B3DD',
            '& *': { transition: 'all .2s ease-in-out' },
            ':hover > *': {
              transform: 'scale(1.1)',
            },
          }}
          to={tab.path}
        >
          {tab.title === 'Rewards' && totalRewardsUsd > 1 ? (
            <Badge
              badgeContent={`$${Math.floor(totalRewardsUsd)}`}
              color="secondary"
              sx={{
                '& .MuiBadge-badge': {
                  position: 'absolute',
                  right: '-0.5rem',
                  top: '0.25rem',
                  color: '#fff',
                },
              }}
              variant="dot"
            >
              <Stack alignItems="center" direction="row" justifyContent="center">
                <tab.icon
                  sx={{
                    mr: { xs: 0, sm: 1 },
                    path: {
                      fill: isActiveTab ? '#6266DB' : '#373741',
                    },
                  }}
                />
                <Typography
                  color={isActiveTab ? '#6266DB' : '#373741'}
                  sx={{
                    fontSize: '14px',
                    display: { xs: 'none', sm: 'block' },
                  }}
                >
                  {tab.title}
                </Typography>
              </Stack>
            </Badge>
          ) : (
            <Typography
              color={isActiveTab ? '#6266DB' : '#373741'}
              sx={{
                fontSize: '14px',
              }}
            >
              <Stack alignItems="center" direction="row" justifyContent="center">
                <tab.icon
                  sx={{
                    mr: { xs: 0, sm: 1 },
                    path: {
                      fill: isActiveTab ? '#6266DB' : '#373741',
                    },
                  }}
                />
                <Typography
                  color={isActiveTab ? '#6266DB' : '#373741'}
                  sx={{
                    fontSize: '14px',
                    display: { xs: 'none', sm: 'block' },
                  }}
                >
                  {tab.title}
                </Typography>
              </Stack>
            </Typography>
          )}
        </Stack>
      </Tooltip>
    </ClickAwayListener>
  );
};

const MobileNavbar: React.FC<BoxProps> = ({ sx, ...rest }) => {
  const tabs = useTabs();

  return (
    <Box
      sx={{
        top: '80px',
        position: 'fixed',
        zIndex: 100,
        background: '#ffffff',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',

        display: { lg: 'none', xs: 'flex' },
        width: '100%',
        ...sx,
      }}
      {...rest}
    >
      {tabs.map(tab => (
        <TabItem key={tab.path} tab={tab} />
      ))}
    </Box>
  );
};

export default MobileNavbar;
