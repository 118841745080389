import { Container, Stack, Typography } from '@mui/material';
import BackButton from 'components/BackButton';
import { useEffect } from 'react';

const FAQ = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
    const contentContainer = document.getElementById('content-container');

    contentContainer?.scrollTo({ top: 0 });
  }, []);

  return (
    <Container
      sx={{
        pt: 10,
        pb: 5,
        '& >div > ol > li': {
          my: 8,
        },
        '& ol li ol': {
          mb: 1.5,
        },
        '& ol li p': {
          my: 1,
        },
      }}
    >
      <Stack spacing={1}>
        <Typography pb={2} variant="h2">
          FAQ on staking/reALT
        </Typography>
        <Typography component="ol">
          <Typography component="li" variant="h4">
            ELI5 What is ALT staking?
            <Typography component="p">
              ALT staking allows ALT holders to stake ALT to earn ALT rewards.
            </Typography>
            <Typography component="p">
              ALT rewards are given out every second. To withdraw your rewards, you will need to
              send an on-chain transaction (using the staking portal). This step is only necessary
              if you do not deposit into the reALT vault.
            </Typography>
            <Typography component="p">
              When you stake ALT, you subject your stake to a 21-day unbonding period.
            </Typography>
          </Typography>
          <Typography component="li" variant="h4">
            What is unbonding?
            <Typography component="p">
              In order to withdraw ALT from the staking contract, you will need to go through the
              following processing which is known as unbonding of the stake
            </Typography>
            <Typography component="ol">
              <Typography component="li">
                Initiate cooldown (21 days) for the specific amount of ALT
              </Typography>
              <Typography component="li">Wait for the cooldown period to be over</Typography>
              <Typography component="li">Initiate the stake withdrawal</Typography>
            </Typography>
            <Typography component="p">
              ALT rewards are given out every second. To withdraw your rewards, you will need to
              send an on-chain transaction (using the staking portal). This step is only necessary
              if you do not deposit into the reALT vault.
            </Typography>
            <Typography component="p">
              When you stake ALT, you subject your stake to a 21-day unbonding period.
            </Typography>
          </Typography>
          <Typography component="li" variant="h4">
            ELI5 What is reALT restaking?
            <Typography component="p">
              reALT stands for restaked ALT. To get reALT, you can deposit your staked ALT into the
              reALT vault.
            </Typography>
            <Typography component="p">
              By depositing your staked ALT into reALT vault, you become eligible to delegate to
              AltLayer LaunchPools.
            </Typography>
            <Typography component="p">
              Additionally, reALT will automatically claim ALT staking rewards and deposit the
              rewards back for staking to get compounded yields for reALT holders. This process is
              done on a weekly basis.
            </Typography>
          </Typography>
          <Typography component="li" variant="h4">
            Can I withdraw reALT and is there any unbonding period for reALT?
            <Typography component="p">
              There is no unbonding period for reALT token withdrawal. Upon withdrawal from the
              reALT vault, you will get back your staked ALT instantly.
            </Typography>
            <Typography component="p">
              The staked ALT can then be redeemed for ALT via the ALT staking contract. This
              redemption is subject to a 21 days unbonding period.
            </Typography>
          </Typography>
          <Typography component="li" variant="h4">
            ELI5 What is a LaunchPool?
            <Typography component="p">
              LaunchPool allows users to earn LaunchPool project&apos;s upcoming tokens by staking
              specific tokens such as reALT, LaunchPool project&apos;s designated token and
              ETH/LSTs. Delegation of ETH and LSTs is done on the EigenLayer restaking platform.
            </Typography>
          </Typography>
          <Typography component="li" variant="h4">
            Can I participate in more than one LaunchPool?
            <Typography component="p">
              reALT is designed such that your stake can be delegated to multiple LaunchPools at the
              same time. For example, if you have 10 reALT tokens and you have delegated to
              LaunchPool X, Y and Z, then, you will have 10 reALT assigned to each of these
              LaunchPools.
            </Typography>
          </Typography>
          <Typography component="li" variant="h4">
            What is the LaunchPool APR and when can we get those rewards?
            <Typography component="p">
              LaunchPool token rewards will be determined by the project teams and the exact future
              allocation and distribution timeline will be dependent on them.
            </Typography>
          </Typography>
          <Typography component="li" variant="h4">
            I am restaking ETH/LSTs with EigenLayer. Why am I not receiving any rewards?
            <Typography component="p">
              Rewards for EigenLayer restakers will only be available once EigenLayer releases its
              payment mechanism on their mainnet.
            </Typography>
          </Typography>
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="center" my={3}>
        <BackButton />
      </Stack>
    </Container>
  );
};

export default FAQ;
