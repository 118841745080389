import { Box, Link as MuiLink, Typography, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconArrowRight from 'components/icons/IconArrowRight';
import React from 'react';
import { Link } from 'react-router-dom';

const links = [
  {
    text: 'ALTLAYER',
    link: 'https://altlayer.io',
  },
  {
    text: 'DOCS',
    link: 'https://docs.altlayer.io/altlayer-documentation',
  },
  {
    text: 'BLOG',
    link: 'https://blog.altlayer.io',
  },
  {
    text: 'CAREERS',
    link: 'https://careers.altlayer.io/',
  },
  {
    text: 'CONTACT',
    link: 'https://linktr.ee/altlayer',
  },
];

const Footer: React.FC = () => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        fontWeight: 900,
        color: '#202723',
        background: 'linear-gradient(154.71deg, #FFFFFF 0%, #F7F7F7 100%)',
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-around"
        sx={{
          flexWrap: 'wrap',
          width: '100%',
          py: 2,
          px: { md: 5, xs: 2 },
          display: { xs: 'none', sm: 'flex' },
        }}
      >
        {links.map(link => (
          <Stack
            alignItems="center"
            component={Link}
            flexDirection="row"
            key={link.text}
            rel="noreferrer noopener"
            sx={{
              color: '#202723',
              mr: 3.75,
              '&:last-of-type': {
                mr: 0,
              },
              '&:hover': { textDecoration: 'underline' },
            }}
            target="_blank"
            to={link.link}
          >
            {link.text}
          </Stack>
        ))}
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        sx={{ display: { xs: 'flex', sm: 'none' } }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          p={4}
          sx={{
            background: 'linear-gradient(154.71deg, #FFFFFF 0%, #F7F7F7 100%)',
          }}
          width="100%"
        >
          <Box
            component="img"
            src="/imgs/altLayerLogo.png"
            sx={{
              p: '0.3rem',
              width: '200px',
              height: 'auto',
            }}
          />
        </Stack>
        {links.map(cur => (
          <Stack
            alignItems="center"
            component={Link}
            direction="row"
            justifyContent="space-between"
            key={cur.text}
            p={4}
            rel="noopener noreferrer"
            sx={{ borderBottom: '1px solid #20272308', '&:hover': { textDecoration: 'underline' } }}
            target="_blank"
            to={cur.link}
            width="100%"
          >
            <Typography>{cur.text}</Typography>
            <IconArrowRight sx={{ width: '14px' }} />
          </Stack>
        ))}
      </Stack>
      <Stack alignItems="center" justifyContent="center" my={1}>
        <Typography fontSize={10} textAlign="center">
          By using this product, you acknowledge having read the{' '}
          <MuiLink
            component={Link}
            sx={{ fontSize: 12, color: theme.colors?.schema?.primary }}
            to="/legal/privacy-policy"
          >
            privacy policy
          </MuiLink>{' '}
          and{' '}
          <MuiLink
            component={Link}
            sx={{ fontSize: 12, color: theme.colors?.schema?.primary }}
            to="/legal/disclaimer"
          >
            legal disclaimer
          </MuiLink>
          .
        </Typography>
        <Typography fontSize={10}>&#169; 2024 by Alt Research</Typography>
      </Stack>
    </Stack>
  );
};

export default Footer;
