import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useStakingInfo } from 'contexts/StakingInfoContext';
import { commify } from 'core/utils';
import { useAPY } from 'hooks/useAPY';
import { NodeBalance } from 'hooks/useComputedStakingValues';
import { Fragment, PropsWithChildren, ReactNode } from 'react';
import { Address } from 'viem';

import { V1VaultDetailsCard } from './PortfolioDetailsCardMobile';
import { VaultsTableRow } from './VaultsTableRow';

const vaultsConfigs = window.appConfig?.vaults || {};

export function RestakingPortfolioTable({
  additionalRows,
  vaultBalances,
}: PropsWithChildren & {
  additionalRows?: ReactNode;
  vaultBalances: [string, Record<Address, NodeBalance>][];
}) {
  const { stakingInfo } = useStakingInfo();

  const hasDisplayableBalances = Boolean(
    additionalRows ||
      vaultBalances?.reduce(
        (sum, [, balanceByContract]) =>
          sum +
          Object.values(balanceByContract || {})?.reduce(
            (contractSum, balances) =>
              contractSum +
              (balances?.accruingRewards || 0n) +
              (balances?.inCooldown || 0n) +
              (balances?.withdrawable || 0n),
            0n
          ),
        0n
      )
  );

  const { stAltApr } = useAPY();

  return (
    <>
      <Table
        sx={{
          'thead > tr': {
            background: '#D5E9BB',
          },
          'tbody > tr': {
            background: 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)',
          },
          display: { xs: 'none', sm: 'table' },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
              {vaultBalances?.find(([vaultId]) => vaultId === 'mach_alpha') ? '' : 'Vault'}
            </TableCell>
            <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>APY</TableCell>
            <TableCell sx={{ display: { xs: 'table-cell', md: 'none' } }}>Vault [APY]</TableCell>
            <TableCell sx={{ width: 150 }}>Balance</TableCell>
            <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>Status</TableCell>
            {/*  Assign fixed cell width on last column to avoid layout shift due to countdown */}
            <TableCell sx={{ width: { xl: 300, md: 200, xs: 150 } }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!hasDisplayableBalances ? (
            <TableRow>
              <TableCell colSpan={99}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="center"
                  sx={{ fontWeight: 900 }}
                >
                  You do not have any staked tokens
                </Stack>
              </TableCell>
            </TableRow>
          ) : (
            <>
              {vaultBalances?.map(([vaultId, vaultBals]) => (
                <Fragment key={vaultId}>
                  <TableRow sx={{ borderBottom: '1px solid #cccccc44' }} />
                  {Object.keys(vaultBals)?.map((contractAddr, contractIndex) => {
                    return (
                      <VaultsTableRow
                        balance={vaultBals[contractAddr as Address]}
                        contractIndex={contractIndex}
                        key={contractAddr}
                        operatorAddr={
                          stakingInfo?.[vaultId]?.[contractAddr as Address]?.operatorAddresses?.[0]
                        } // assume only 1 operator per contract
                        stakingContractAddr={contractAddr as Address}
                        vaultConfig={vaultsConfigs?.[vaultId]}
                      />
                    );
                  })}
                </Fragment>
              ))}
              {additionalRows}
            </>
          )}
        </TableBody>
      </Table>
      {!hasDisplayableBalances ? (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          my={6}
          px={3}
          sx={{ display: { xs: 'flex', sm: 'none' } }}
        >
          You do not have any staked tokens
        </Stack>
      ) : (
        vaultBalances?.map(([vaultId]) => (
          <Box key={vaultId} sx={{ display: { xs: 'block', sm: 'none' } }}>
            <V1VaultDetailsCard
              apy={`${commify((stAltApr * 100).toFixed(1))}%`}
              vaultId={vaultId}
            />
            {additionalRows}
          </Box>
        ))
      )}
    </>
  );
}
