import { GetComponentStyleFunc } from './types';

export const muiDialog: GetComponentStyleFunc<'MuiDialog'> = () => {
  return {
    styleOverrides: {
      paper: {
        background: '#F3FAE5',
        borderRadius: 0,
        maxWidth: 800,
        zIndex: 1000,
        padding: 16,
      },
    },
  };
};

export const muiDialogActions: GetComponentStyleFunc<'MuiDialogActions'> = () => {
  return {};
};

export const muiDialogContent: GetComponentStyleFunc<'MuiDialogContent'> = () => {
  return {
    styleOverrides: {
      root: {
        padding: 16,
      },
    },
  };
};

export const muiDialogContentText: GetComponentStyleFunc<'MuiDialogContentText'> = () => {
  return {};
};

export const muiDialogTitle: GetComponentStyleFunc<'MuiDialogTitle'> = theme => {
  return {
    styleOverrides: {
      root: {
        color: theme.colors.functional.text.primary,
        fontSize: '16px',
        fontWeight: '700',
        padding: 16,
      },
    },
  };
};
