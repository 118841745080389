import { darken } from '@mui/material';
import { connectorsForWallets, lightTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { RainbowKitChain } from '@rainbow-me/rainbowkit/dist/components/RainbowKitProvider/RainbowKitChainContext';
import {
  coinbaseWallet,
  metaMaskWallet,
  okxWallet,
  rabbyWallet,
  tokenPocketWallet,
  trustWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { RpcErrorType } from 'viem';
import { Config, createConfig, http, WagmiProvider as _WagmiProvider } from 'wagmi';

const rpcUrls = window?.appConfig?.rpcUrls;

const appNetwork = window?.appConfig?.appNetwork || {};

interface Context {
  config: Config;
  chains: readonly [RainbowKitChain, ...RainbowKitChain[]];
  switchRpc: () => void;
  switchRpcIfError: (err: RpcErrorType) => void;
}

export const WagmiContext = createContext<Context>({} as Context);

export const useWagmi = () => {
  return useContext(WagmiContext);
};

const theme = lightTheme();

// These 2 colors should be the same for UI to look cohesive
theme.colors.accentColor = darken('#D5E9BB', 0.5); // Affects connect modal font colors and 'Get a Wallet' button background
theme.colors.modalTextSecondary = darken('#D5E9BB', 0.5); // Affects other connect modal font colors

theme.colors.profileForeground = '#EAF4DC';
theme.colors.modalBackground = '#EAF4DC';
theme.colors.connectButtonBackground = 'transparent';
theme.colors.connectButtonInnerBackground = 'transparent';
theme.colors.generalBorder = 'transparent';
theme.colors.accentColorForeground = '#f7f7f7';
theme.colors.actionButtonSecondaryBackground = darken('#202723', 0.02);
theme.colors.profileAction = '#D5E9BB';

theme.radii.modal = '0px';
theme.radii.connectButton = '0px';
theme.radii.actionButton = '0px';
theme.radii.menuButton = '0px';
theme.radii.modalMobile = '0px';

theme.shadows.connectButton = 'none';

theme.fonts.body = 'ABCDiatypeSemiMono'; // Default value: SFRounded, ui-rounded, "SF Pro Rounded", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"

export const WagmiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [chains, setChains] = useState<readonly [RainbowKitChain, ...RainbowKitChain[]]>([
    appNetwork,
  ]);

  const connectors = useMemo(
    () =>
      connectorsForWallets(
        [
          {
            groupName: 'Recommended',
            wallets: [
              metaMaskWallet,
              rabbyWallet,
              walletConnectWallet,
              coinbaseWallet,
              okxWallet,
              tokenPocketWallet,
              trustWallet,
            ],
          },
        ],
        {
          appName: 'AltLayer Restaking App',
          projectId: '3d27bfc93787d97f1103ec8fa5367aad',
        }
      ),
    []
  );

  const config = useMemo(
    () =>
      createConfig({
        connectors,
        chains,
        transports: {
          [appNetwork?.id]: http(undefined, { retryCount: 1 }),
        },
      }),
    [chains, connectors]
  );

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
      },
    },
  });

  const switchRpc = useCallback(() => {
    const currRpcIndex = rpcUrls?.findIndex(
      cur => cur === chains?.[0]?.rpcUrls?.default?.http?.[0]
    );
    const newRpcUrl = rpcUrls?.[currRpcIndex + 1];

    console.log('curr RPC: ', chains?.[0]?.rpcUrls?.default?.http?.[0]);
    console.log('next RPC: ', newRpcUrl);
    setChains(prev => [
      {
        ...prev[0],
        rpcUrls: {
          default: {
            http: [newRpcUrl],
          },
          public: {
            http: [newRpcUrl],
          },
        },
      },
    ]);
  }, [chains]);

  // Necessary for the scenario where multiple read/write calls try to switch RPC at the same time.
  const debouncedSwitchRpc = useMemo(() => {
    return debounce(switchRpc, 1000);
  }, [switchRpc]);

  const switchRpcIfError = useCallback(
    (err: RpcErrorType) => {
      // if (err?.details?.toLowerCase() === 'failed to fetch') {
      //   debouncedSwitchRpc(); // Assume RPC error for specific string
      // }

      console.debug(err);
      debouncedSwitchRpc(); // Assume RPC error for ALL errors returned
    },
    [debouncedSwitchRpc]
  );

  return (
    <WagmiContext.Provider
      value={{ chains, config, switchRpc: debouncedSwitchRpc, switchRpcIfError }}
    >
      <_WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider showRecentTransactions theme={theme}>
            {children}
          </RainbowKitProvider>
        </QueryClientProvider>
      </_WagmiProvider>
    </WagmiContext.Provider>
  );
};
