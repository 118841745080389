import './CustomConnectButton.css';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, darken, FormControlLabel, Stack, Typography } from '@mui/material';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';

const TermsAcknowledgement: FC<PropsWithChildren> = () => {
  const [agreed, setAgreed] = useState(false);

  useEffect(() => {
    const disabledSections = [
      document.querySelector('.walletSelectionSection'),
      document.querySelector('.mobileContent'),
      document.querySelector('.learnMoreContent'),
    ];

    if (agreed) {
      disabledSections?.forEach(cur => cur?.classList?.remove('disableSection'));
    } else {
      disabledSections?.forEach(cur => cur?.classList?.add('disableSection'));
    }
  }, [agreed]);

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ p: 3 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={agreed}
            onChange={() => {
              setAgreed(prev => !prev);
            }}
            ref={undefined}
          />
        }
        label={
          <Typography fontSize={12} fontWeight={700}>
            By proceeding, I acknowledge having read the{' '}
            <a href="/legal/privacy-policy" rel="noopener noreferrer" target="_blank">
              privacy policy
            </a>{' '}
            and{' '}
            <a href="/legal/disclaimer" rel="noopener noreferrer" target="_blank">
              disclaimer
            </a>
          </Typography>
        }
        labelPlacement="end"
      />
    </Stack>
  );
};

export const ConnectWithTermsButton: FC<PropsWithChildren> = props => {
  return (
    <ConnectButton.Custom>
      {({ authenticationStatus, mounted, openConnectModal }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';

        if (!ready) {
          return <LoadingButton fullWidth loading={true} variant="contained" {...props} />;
        }

        const openCustomConnectModal = () => {
          openConnectModal();
          setTimeout(() => {
            const el = document.querySelector(
              'div[data-rk][aria-labelledby="rk_connect_title"] div[tabIndex="-1"][role="document"]>div>div'
            );
            const rkModal = el?.firstElementChild;

            if (rkModal) {
              rkModal.id = 'rkModalContent';
            }

            const mobileContent = el?.querySelector('#rkModalContent>div>div:nth-of-type(2)');

            if (mobileContent) {
              mobileContent.classList?.add('mobileContent');
              mobileContent.classList?.add('disableSection');
            }

            const walletSelection = el?.querySelector('#rkModalContent>div>div>div:nth-of-type(2)');

            if (walletSelection) {
              walletSelection.classList?.add('walletSelectionSection');
              walletSelection.classList?.add('disableSection');
            }

            const learnMore = el?.querySelector(
              '#rkModalContent>div>div:nth-of-type(3)>div:nth-of-type(2)'
            );

            if (learnMore) {
              learnMore.classList?.add('learnMoreContent');
              learnMore.classList?.add('disableSection');
            }

            const learnMoreMobile = el?.querySelector('#rkModalContent>div>div>div:nth-of-type(4)');

            if (learnMoreMobile) {
              learnMoreMobile.classList?.add('learnMoreContent');
              learnMoreMobile.classList?.add('disableSection');
            }

            const div = document.createElement('div', {});

            el?.appendChild(div);

            if (div) {
              const root = createRoot(div);

              root.render(<TermsAcknowledgement />);
            }
          });
        };

        return (
          <Box
            sx={{
              ...(!ready
                ? {
                    opacity: 0,
                    pointerEvents: 'none',
                    userSelect: 'none',
                  }
                : null),
            }}
            width="100%"
          >
            <Button
              fullWidth
              onClick={openCustomConnectModal}
              sx={{
                bgcolor: 'transparent',
                fontWeight: 900,
                '&:hover': {
                  background: `linear-gradient(127.48deg, ${darken('#EAF4DC', 0.02)} 0%, ${darken(
                    '#D5E9BB',
                    0.02
                  )} 100%)`,
                  boxShadow: 'none',
                },
                boxShadow: 'none',
              }}
              {...props}
            >
              <Typography className="hover-grow">Connect Wallet</Typography>
            </Button>
          </Box>
        );
      }}
    </ConnectButton.Custom>
  );
};
