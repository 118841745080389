import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { VaultConfig } from 'types';

import StakeWidget from './StakeWidget';

export default function Stake({ vaults }: PropsWithChildren & { vaults: VaultConfig[] }) {
  const location = useLocation();
  const isRestakeTabActive = location.pathname?.includes('restake');

  return (
    <Stack justifyContent="center" px={{ md: 10, xs: 4 }} py={10}>
      <StakeWidget
        sx={{ background: isRestakeTabActive ? '#2A4C65' : '#F3FAE5' }}
        vaults={vaults}
      />
    </Stack>
  );
}
