import { BoxProps, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CardContainer } from 'components/CardContainer';
import RibbonStepper from 'components/RibbonStepper';
import { SwapStkAltContent } from 'components/SwapStkAltDialog';
import { useMemo, useState } from 'react';
import { VaultConfig } from 'types';

import StepStake from './StepStake';

const stepLabels = ['Stake', 'Convert'];

export default function StakeWidget({ sx, vaults, ...rest }: BoxProps & { vaults: VaultConfig[] }) {
  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);

  const steps = useMemo(
    () => [
      {
        content: <StepStake setActiveStep={setActiveStep} vaults={vaults} />,
      },
      {
        content: <SwapStkAltContent />,
      },
    ],
    [setActiveStep, vaults]
  );

  return (
    <>
      <CardContainer
        sx={{
          background: theme.colors.schema.primary,
          maxWidth: '550px',
          width: 'calc(100vw - 4rem)',
          color: '#202723',
          mx: 'auto',
          ...sx,
        }}
        {...rest}
      >
        <Stack alignItems="center" direction="row" justifyContent="center">
          <RibbonStepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            stepLabels={stepLabels}
          />
        </Stack>
        {steps?.[activeStep]?.content}
      </CardContainer>
    </>
  );
}
