import { GetComponentStyleFunc } from './types';

export const muiTable: GetComponentStyleFunc<'MuiTable'> = () => {
  return {
    styleOverrides: {
      root: {
        overflow: 'hidden',
        'th, td': {
          px: { xs: 1, md: 2, xl: 3 },
          borderBottom: 'none',
        },
        th: {
          fontWeight: 900,
          fontSize: 16,
        },
        'thead > tr': {
          background: 'linear-gradient(118.58deg, #F6F6F6 0%, #E7E7E7 100%)',
        },
        'tbody > tr': {
          background: 'linear-gradient(154.71deg, #FFFFFF 0%, #F7F7F7 100%)',
        },
      },
    },
  };
};
