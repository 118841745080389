import {
  Box,
  Grid,
  Link as MuiLink,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Stack, { StackProps } from '@mui/material/Stack';
import ActionMenu from 'components/ActionMenu';
import IconExtLink from 'components/icons/IconExtLink';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import { useV2Vaults } from 'contexts/V2VaultsContext';
import { commify, formatWithPrecision } from 'core/utils';
import { useAPY } from 'hooks/useAPY';
import { NodeBalance, useComputedStakingValues } from 'hooks/useComputedStakingValues';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Address } from 'viem';

import { DetailsCardMobile } from './PortfolioDetailsCardMobile';
import { RestakingPortfolioTable } from './RestakingPortfolioTable';
import UnstakeReALTButton from './UnstakeReALTButton';
import { VaultsPortfolioTable } from './VaultsPortfolioTable';

export function Statistic({
  details,
  sx,
  symbol,
  title,
  value,
}: {
  title: string;
  value: string;
  symbol?: string;
  details?: ReactNode;
} & StackProps) {
  return (
    <Stack
      px={{ xs: 3, lg: 4 }}
      py={3}
      sx={{
        background: 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)',
        borderBottom: '1px solid #20272317',
        height: '100%',
        ...sx,
      }}
    >
      <Stack alignItems="center" direction="row" flexWrap="nowrap">
        <Typography letterSpacing="0.4px" mr={0.5} variant="h1" whiteSpace="nowrap">
          {value}
        </Typography>
        <Typography variant="h1">{symbol}</Typography>
      </Stack>
      <Typography textTransform="uppercase" variant="h6" whiteSpace="nowrap">
        {title}
      </Typography>
      {details}
    </Stack>
  );
}

const reAltAddr = window.appConfig?.erc4626Vault;
const v2Vaults = window.appConfig?.v2Vaults;
const erc20Vaults = Object.values(v2Vaults || {})?.filter(cur => Boolean(cur?.erc20VaultAddr));

export default function Portfolio() {
  const { totalAccruingRewards } = useComputedStakingValues();
  const { userV2Vaults } = useV2Vaults();
  const { prices, reAltPerStAltRate, tokens } = useStakedTokens();

  const tokensAccruingRewardsUsd = (Object.keys(totalAccruingRewards || {}) as Address[])?.reduce(
    (acc, stakedTokenAddr) => ({
      ...acc,
      [stakedTokenAddr]:
        Number(formatWithPrecision(totalAccruingRewards?.[stakedTokenAddr])) *
        prices?.[stakedTokenAddr],
    }),
    {} as Record<Address, number>
  );
  const totalAccruingRewardsUsd = Object.values(tokensAccruingRewardsUsd || {})?.reduce(
    (sum, cur) => sum + cur,
    0
  );
  const reAltBalanceUsd =
    Number(formatWithPrecision(tokens?.[reAltAddr]?.balance || 0n)) * (prices?.[reAltAddr] || 0);

  const erc20VaultsBalanceUsd = erc20Vaults?.reduce(
    (sum, cur) =>
      sum +
      Number(formatWithPrecision(tokens?.[cur.erc20VaultAddr as Address]?.balance || 0n)) *
        (prices?.[cur.stakedTokenAddr as Address] || 0),
    0
  );

  const totalStakedBalanceUsd = totalAccruingRewardsUsd + reAltBalanceUsd + erc20VaultsBalanceUsd;

  const { reAltApy, vaultsApr } = useAPY();

  const { nodeBalances } = useComputedStakingValues();

  const balanceByVaultAndContract = Object.entries(nodeBalances || {})?.reduce(
    (acc, [vaultId, balanceByContractAndOperator]) => {
      const balanceByContract = Object.entries(balanceByContractAndOperator || {})?.reduce(
        (acc2, [contractAddr, balanceByOperator]) => {
          return {
            ...acc2,
            [contractAddr]: Object.values(balanceByOperator || {})?.[0], // assume only 1 operator per contract
          };
        },
        {} as Record<Address, NodeBalance>
      );

      return { ...acc, [vaultId]: balanceByContract };
    },
    {} as Record<string, Record<Address, NodeBalance>>
  );

  console.debug('balance by vault and contract: ', balanceByVaultAndContract);

  const restakingVaults = Object.entries(balanceByVaultAndContract || {})?.filter(
    ([vaultId]) => vaultId === 'mach_alpha'
  );
  const otherVaults = Object.entries(balanceByVaultAndContract || {})?.filter(
    ([vaultId]) => vaultId !== 'mach_alpha'
  );

  const reAltApyPerc = (reAltApy * 100)?.toFixed(2);

  const portfolioApy = totalStakedBalanceUsd
    ? (vaultsApr * totalAccruingRewardsUsd + reAltApy * reAltBalanceUsd) / totalStakedBalanceUsd
    : 0;

  const portfolioPercentApy = (portfolioApy * 100).toFixed(2);

  const altEquivalentRestakedBN = reAltPerStAltRate
    ? ((tokens?.[reAltAddr]?.balance || 0n) * 1000000n) /
      BigInt(Math.round(reAltPerStAltRate * 1000000))
    : 0n;
  const altEquivalentRestaked = formatWithPrecision(altEquivalentRestakedBN);

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { sm: '2fr 3fr', xs: 'auto' },
          width: '100%',
        }}
      >
        <Statistic
          sx={{ gridArea: { xs: 'unset', sm: portfolioApy > 0 ? 'unset' : '1 / 1 / 3 / 2' } }}
          title="TOTAL STAKED BALANCE"
          value={`$${commify(totalStakedBalanceUsd?.toFixed(2))}`}
        />
        {portfolioApy > 0 && (
          <Statistic
            title="PORTFOLIO EST. APY"
            value={vaultsApr || reAltApy ? `${commify(portfolioPercentApy)}%` : 'N/A'}
          />
        )}
        <Stack
          sx={{
            gridArea: { xs: 'unset', sm: '1 / 2 / 3 / 2' },
            background: 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)',
            borderBottom: '1px solid #20272317',
            p: { xs: 0, sm: 3 },
          }}
        >
          <Stack
            alignItems="center"
            columnGap={2}
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
            sx={{
              p: {
                xs: 3,
                sm: 0,
              },
              pb: {
                sm: 3,
              },
              background: { xs: '#EAF4DC', sm: 'transparent' },
            }}
          >
            <Typography variant="h2">Restaking</Typography>
            <MuiLink
              component={Link}
              rel="noopener noreferrer"
              sx={{
                fontWeight: 600,
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              target="_blank"
              to="https://app.eigenlayer.xyz"
            >
              View ETH balance on Eigenlayer
              <IconExtLink />
            </MuiLink>
          </Stack>
          <RestakingPortfolioTable
            additionalRows={
              tokens?.[reAltAddr]?.balance ? (
                <>
                  <TableRow sx={{ display: { xs: 'none', sm: 'table-row' } }}>
                    <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                      <Stack alignItems="center" direction="row" gap={1.5}>
                        <Box
                          component="img"
                          src={'/icons/iconReAlt.svg'}
                          sx={{
                            width: 24,
                            borderRadius: '50%',
                          }}
                        />
                        reALT
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                      {commify(reAltApyPerc)}%
                    </TableCell>
                    <TableCell sx={{ display: { xs: 'table-cell', md: 'none' } }}>
                      <Stack alignItems="center" direction="row" gap={1.5}>
                        <Box
                          component="img"
                          src={'/icons/iconReAlt.svg'}
                          sx={{
                            width: 24,
                            borderRadius: '50%',
                          }}
                        />
                        reALT [{commify(reAltApyPerc)}%]
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack alignItems="center" direction="row">
                        <Tooltip
                          placement="right"
                          title={`~$${commify(reAltBalanceUsd?.toFixed(2))}`}
                        >
                          <Stack alignItems="center" direction="row">
                            <Box
                              component="img"
                              src={'/icons/iconAltLayer.svg'}
                              sx={{
                                width: 24,
                                mr: 1,
                              }}
                            />
                            <Typography
                              sx={{
                                cursor: 'default',
                                whiteSpace: 'nowrap',
                                ':hover': { textDecoration: 'underline' },
                              }}
                            >
                              {commify(altEquivalentRestaked)} stALT
                            </Typography>
                          </Stack>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>Staked</TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'right',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ActionMenu
                        renderPopoverContent={() => <UnstakeReALTButton />}
                        sx={{ display: { xs: 'block', md: 'none' } }}
                      />
                      <Stack
                        columnGap={2}
                        direction="row"
                        justifyContent="flex-end"
                        sx={{ display: { xs: 'none', md: 'flex' } }}
                      >
                        <UnstakeReALTButton />
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <DetailsCardMobile
                    apy={`${commify(reAltApyPerc)}%`}
                    balance={
                      <Tooltip
                        placement="right"
                        title={`~$${commify(reAltBalanceUsd?.toFixed(2))}`}
                      >
                        <Typography
                          component="span"
                          sx={{ cursor: 'default', ':hover': { textDecoration: 'underline' } }}
                        >
                          {commify(altEquivalentRestaked)} stALT
                        </Typography>
                      </Tooltip>
                    }
                    status="Staked"
                    sx={{ background: 'inherit' }}
                    vaultConfig={{
                      name: 'reALT',
                      iconUrl: '/icons/IconReAlt.svg',
                      desc: '',
                      twitter: '',
                      website: '',
                    }}
                  >
                    <Grid item xs={12}>
                      <UnstakeReALTButton fullWidth />
                    </Grid>
                  </DetailsCardMobile>
                </>
              ) : null
            }
            vaultBalances={restakingVaults}
          />
        </Stack>
      </Box>

      <Stack
        sx={{
          flex: 1,
          background: 'linear-gradient(154.71deg, #FFFFFF 0%, #F7F7F7 100%)',
          pb: 6,
          px: { sm: 4, xs: 0 },
        }}
      >
        <Typography
          mb={{ xs: 0, sm: 2 }}
          mt={{ xs: 0, sm: 4 }}
          sx={{
            background: {
              xs: 'linear-gradient(118.58deg, #F6F6F6 0%, #E7E7E7 100%)',
              sm: 'inherit',
            },
            p: { xs: 3, sm: 0 },
          }}
          variant="h2"
        >
          Vaults
        </Typography>
        <VaultsPortfolioTable v1VaultBalances={otherVaults} v2VaultIds={userV2Vaults} />
      </Stack>
    </>
  );
}
