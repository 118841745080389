import { alpha } from '@mui/material';

import { GetComponentStyleFunc } from './types';

export const muiOutlinedInput: GetComponentStyleFunc<'MuiOutlinedInput'> = theme => {
  return {
    styleOverrides: {
      root: {
        borderRadius: '0px',
        background: theme.colors.functional.container.first,
        border: 'none',
        input: {
          ':placeholder': {
            fontWeight: 'Medium',
            color: theme.colors.functional.text.third,
          },
          height: '60px',
          padding: '0px 0px 0px 14px',
          fontSize: '24px',
          lineHeight: '20px',
        },
        fieldset: {
          border: 'none',
        },
        '&:hover fieldset': {
          border: 'none',
        },
        '&.Mui-focused fieldset': {
          border: 'none',
        },
      },
    },
  };
};

export const muiInput: GetComponentStyleFunc<'MuiInputBase'> = theme => {
  return {
    styleOverrides: {
      root: {
        input: {
          color: alpha(theme.colors.functional.text.primary, 0.7),
        },
        '&.MuiInput-underline::before': {
          borderBottomColor: '#7e7e8c',
        },
        '&.MuiInput-underline:hover::before': {
          borderBottomColor: alpha('#7e7e8c', 0.85),
        },
      },
    },
  };
};
