import { GetComponentStyleFunc } from './types';

export const muiBreadcrumbs: GetComponentStyleFunc<'MuiBreadcrumbs'> = theme => {
  return {
    styleOverrides: {
      root: {
        '& .MuiBreadcrumbs-separator': {
          color: theme.colors.schema.secondary,
        },
      },
    },
  };
};
