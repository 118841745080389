import {
  Box,
  Link as MuiLink,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import IconExtLink from 'components/icons/IconExtLink';
import { useV2Vaults } from 'contexts/V2VaultsContext';
import { useComputedStakingValues } from 'hooks/useComputedStakingValues';
import RewardDetailsCardMobile from 'pages/Rewards/RewardDetailsCardMobile';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

const vaultsConfigs = window.appConfig?.vaults || {};
const v2VaultsConfigs = window.appConfig?.v2Vaults || {};

export function VaultRewardsTable() {
  const { userV2Vaults } = useV2Vaults();
  const { nodeBalances } = useComputedStakingValues();

  const v1VaultsWithBalances = Object.entries(nodeBalances || {})
    ?.reduce((acc, [vaultId, contractsMap]) => {
      const vaultHasBalance = Object.values(contractsMap || {})?.some(operatorMap => {
        const balances = Object.values(operatorMap || {})?.[0];

        return balances?.accruingRewards || balances?.inCooldown || balances?.withdrawable;
      });

      return vaultHasBalance ? [...acc, vaultId] : acc;
    }, [] as string[])
    ?.filter(cur => cur !== 'mach_alpha');

  const hasRewards = Boolean(v1VaultsWithBalances?.length || userV2Vaults?.length);

  return (
    <>
      <Table sx={{ display: { xs: 'none', sm: 'table' } }}>
        <TableHead>
          <TableRow>
            <TableCell>Vault</TableCell>
            <TableCell>APY</TableCell>
            <TableCell>Rewards</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {!hasRewards ? (
            <TableRow>
              <TableCell colSpan={99}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="center"
                  sx={{ fontWeight: 900 }}
                >
                  You do not have any claimable rewards
                </Stack>
              </TableCell>
            </TableRow>
          ) : (
            <>
              {[...v1VaultsWithBalances, ...userV2Vaults]?.map(vaultId => {
                const vaultConfig =
                  typeof vaultId === 'number'
                    ? v2VaultsConfigs?.[vaultId]
                    : vaultsConfigs?.[vaultId];

                return (
                  <Fragment key={vaultId}>
                    <TableRow
                      sx={{
                        borderBottom: '1px solid #2027230F',
                      }}
                    />
                    <TableRow>
                      <TableCell>
                        <Stack alignItems="center" direction="row" gap={1}>
                          <Box
                            component="img"
                            src={vaultConfig?.iconUrl}
                            sx={{
                              width: 24,
                              marginRight: 0.5,
                            }}
                          />
                          {vaultConfig?.name}
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {v2VaultsConfigs?.[Number(vaultId)]?.isLive ? '' : 'LaunchPool'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {v2VaultsConfigs?.[Number(vaultId)]?.rewardsUrl ? (
                          <MuiLink
                            component={Link}
                            rel="noopener noreferrer"
                            sx={{
                              fontWeight: 600,
                              '&:hover': {
                                textDecoration: 'underline',
                              },
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                            }}
                            target="_blank"
                            to={v2VaultsConfigs?.[Number(vaultId)]?.rewardsUrl || ''}
                          >
                            <Typography>
                              {v2VaultsConfigs?.[Number(vaultId)]?.rewardsUrl}
                            </Typography>
                            <IconExtLink sx={{ position: 'static' }} />
                          </MuiLink>
                        ) : (
                          <Typography>Coming Soon</Typography>
                        )}
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </Fragment>
                );
              })}
            </>
          )}
        </TableBody>
      </Table>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        {!hasRewards ? (
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            p={3}
            sx={{ fontWeight: 900 }}
          >
            <Typography>You do not have any claimable rewards</Typography>
          </Stack>
        ) : (
          [...v1VaultsWithBalances, ...userV2Vaults]?.map(vaultId => (
            <RewardDetailsCardMobile key={vaultId} vaultId={String(vaultId)} />
          ))
        )}
      </Box>
    </>
  );
}
