import { Button, ButtonProps } from '@mui/material';
import { useNavigate } from 'react-router';

const BackButton = (props: ButtonProps) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(-1);
    setTimeout(() => window.scrollTo({ top: 0 }), 10);
  };

  return (
    <Button onClick={onClick} {...props}>
      Back
    </Button>
  );
};

export default BackButton;
