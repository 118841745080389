import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';
import { useAlerts } from 'contexts/AlertsContext';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import { useStakingInfo } from 'contexts/StakingInfoContext';
import { useTxStatus } from 'contexts/TxStatusContext';
import REALT_VAULT_ABI from 'core/contracts/REALT_VAULT_ABI';
import STAKE_ABI from 'core/contracts/STAKE_ABI';
import { commify } from 'core/utils';
import { useAmount } from 'hooks/useAmount';
import { useContractRead } from 'hooks/useContractRead';
import { useWriteTx } from 'hooks/useWriteTx';
import { FC, ReactNode, useState } from 'react';
import { formatUnits } from 'viem';
import { useAccount } from 'wagmi';

import IconArrowDownFilled from './icons/IconArrowDownFilled';
import MaxButton from './MaxButton';
import MaximizableAmount from './MaximizableAmount';
import { ConditionalConnectButton } from './Web3Route';

const vaultAddr = window.appConfig?.erc4626Vault;
const altStakingContract = window.appConfig?.vaults?.mach_alpha?.stakingContracts?.[0];

interface SwapStkAltDialogProps {
  open: boolean;
  title?: ReactNode;
  handleClose: () => void;
  isLoading?: boolean;
}

const SwapStkAltDialog: FC<SwapStkAltDialogProps & Omit<DialogProps, 'title'>> = ({
  handleClose,
  open = false,
  sx,
  title = 'Convert stALT',
  ...rest
}) => {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          background: '#F3FAE5',
          borderRadius: 0,
          maxWidth: 800,
        },
        '&': {
          zIndex: 1000,
        },
        ...sx,
      }}
      {...rest}
    >
      <DialogTitle>
        <Typography fontWeight={500} variant="h2">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <SwapStkAltContent />
      </DialogContent>
    </Dialog>
  );
};

export const SwapStkAltContent: FC<StackProps & { swapButtonProps?: LoadingButtonProps }> = ({
  swapButtonProps,
  sx,
  ...rest
}) => {
  const { address } = useAccount();
  const { showReadContractError } = useAlerts();
  const {
    data: isApproved,
    isLoading: isCheckingApproval,
    refetch: refetchIsApproved,
  } = useContractRead<bigint>({
    address: altStakingContract,
    abi: STAKE_ABI,
    functionName: 'isApprovedForAll',
    args: [address, vaultAddr],
    enabled: Boolean(address && altStakingContract),
    onError: (err: Error) => {
      showReadContractError('isApprovedForAll', err);
    },
  });

  const approveTxKey = `setApprovalForAll_${altStakingContract}`;
  const { pendingTxs } = useTxStatus();
  const isApproving = pendingTxs?.has(approveTxKey);
  const { write: approve } = useWriteTx({
    contractAbi: STAKE_ABI,
    contractAddress: altStakingContract,
    functionName: 'setApprovalForAll',
    functionArgs: [vaultAddr, true],
    txKey: approveTxKey,
    onTxConfirmed: () => {
      refetchIsApproved();
    },
  });

  const { fetchTokenBalance, reAltPerStAltRate, tokens } = useStakedTokens();

  // TODO: Remove useage of setState if no need to swap sides. Keeping it here for now.
  const [swapFrom] = useState(altStakingContract);
  const [swapTo] = useState(vaultAddr);

  const {
    amountBN: fromAmountBN,
    amountRaw: fromAmountRaw,
    setAmountRaw: setFromAmountRaw,
  } = useAmount(tokens?.[swapFrom]?.decimals || 18);

  const { amountRaw: toAmountRaw, setAmountRaw: setToAmountRaw } = useAmount(
    tokens?.[swapTo]?.decimals || 18
  );

  const { refetchNodeStakedBalance, stakingInfo } = useStakingInfo();
  const stkAltOperatorAddr = stakingInfo?.mach_alpha?.[altStakingContract]?.operatorAddresses?.[0];

  const depositTxKey = `deposit_${altStakingContract}`;
  const isDepositing = pendingTxs?.has(depositTxKey);
  const { write: deposit } = useWriteTx({
    contractAbi: REALT_VAULT_ABI,
    contractAddress: vaultAddr,
    functionName: 'deposit',
    functionArgs: [fromAmountBN, address],
    txKey: approveTxKey,
    onTxConfirmed: () => {
      fetchTokenBalance(altStakingContract, stkAltOperatorAddr);
      fetchTokenBalance(vaultAddr);
      refetchNodeStakedBalance('mach_alpha', altStakingContract, stkAltOperatorAddr);
    },
  });

  const handleAction = () => {
    if (!isApproved) {
      approve();
    } else {
      deposit();
    }
  };

  return (
    <Stack sx={sx} {...rest}>
      <MaximizableAmount
        endAdornment={
          <FormControl sx={{ minWidth: { xs: 100, lg: 140 } }}>
            <Typography
              sx={{
                fontSize: { xs: 14, sm: 18 },
                color: theme => theme.colors.schema.primary,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                columnGap: 0.75,
              }}
            >
              <Box component="img" src="/icons/iconAltLayer.svg" sx={{ width: 26 }} />
              {tokens?.[swapFrom]?.symbol}
            </Typography>
          </FormControl>
        }
        fullWidth
        setAmountRaw={(val: string) => {
          setFromAmountRaw(val);
          const toValue = val?.match(/^\d*\.{0,1}\d*$/) ? val : val?.slice(0, -1);

          setToAmountRaw(String(reAltPerStAltRate * Number(toValue)));
        }}
        sx={{ mt: 1 }}
        tokenSymbol={tokens?.[swapFrom]?.symbol}
        value={fromAmountRaw}
      />
      <Stack direction="row" justifyContent="space-between" mt={2}>
        <Typography variant="subtitle1">
          BALANCE:&nbsp;
          <Typography component="span" variant="subtitle1">
            {commify(tokens?.[swapFrom]?.formattedBalance) || '-'}
          </Typography>
          <Typography component="span" variant="subtitle1">
            &nbsp;{tokens?.[swapFrom]?.symbol}
          </Typography>
        </Typography>
        <MaxButton
          onClick={() => {
            const stringVal = formatUnits(
              tokens?.[swapFrom]?.balance,
              tokens?.[swapFrom]?.decimals
            );

            setFromAmountRaw(stringVal);
            setToAmountRaw(String(reAltPerStAltRate * Number(stringVal)));
          }}
        />
      </Stack>
      <Stack my={2}>
        <IconArrowDownFilled sx={{ mx: 'auto', width: 38, height: 38 }} />
      </Stack>
      <MaximizableAmount
        endAdornment={
          <FormControl sx={{ minWidth: { xs: 100, lg: 140 } }}>
            <Typography
              sx={{
                fontSize: { xs: 14, sm: 18 },
                color: theme => theme.colors.schema.primary,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                columnGap: 0.75,
              }}
            >
              <Box
                component="img"
                src="/icons/iconReAlt.svg"
                sx={{
                  width: 26,
                  bgcolor: 'white',
                  border: '1px solid rgb(237,235,254)',
                  borderRadius: '50%',
                  ...sx,
                }}
                {...rest}
              />
              {tokens?.[swapTo]?.symbol}
            </Typography>
          </FormControl>
        }
        fullWidth
        setAmountRaw={(val: string) => {
          setToAmountRaw(val);
          setFromAmountRaw(String(Number(val) / reAltPerStAltRate));
        }}
        sx={{ mt: 1 }}
        tokenSymbol={tokens?.[swapTo]?.symbol}
        value={toAmountRaw}
      />
      <Typography mt={{ md: 1.25, xs: 1 }} variant="subtitle1">
        BALANCE:&nbsp;
        <Typography component="span" variant="subtitle1">
          {commify(tokens?.[swapTo]?.formattedBalance) || '-'}
        </Typography>
        <Typography component="span" variant="subtitle1">
          &nbsp;{tokens?.[swapTo]?.symbol}
        </Typography>
      </Typography>
      <Typography
        fontSize={14}
        my={3}
        sx={{ background: 'linear-gradient(154.71deg, #FFFFFF 0%, #F7F7F7 100%)', py: 1 }}
        textAlign="center"
      >
        1 {tokens?.[swapFrom]?.symbol} ≈ {reAltPerStAltRate} {tokens?.[swapTo]?.symbol}
      </Typography>
      <Stack
        className="loadingButtonContainer"
        direction="row"
        justifyContent="flex-end"
        spacing={1}
        sx={{ '& button': { minWidth: 80 } }}
      >
        <ConditionalConnectButton chainId={window.appConfig?.appNetwork?.id}>
          <LoadingButton
            className="confirm-button"
            disabled={!fromAmountBN || fromAmountBN > tokens?.[altStakingContract]?.balance}
            fullWidth
            key="confirm"
            loading={isCheckingApproval || isApproving || isDepositing}
            onClick={handleAction}
            sx={{ py: 1.5 }}
            variant="contained"
            {...swapButtonProps}
          >
            {!isApproved ? 'Approve' : 'Convert'}
          </LoadingButton>
        </ConditionalConnectButton>
      </Stack>
    </Stack>
  );
};

export default SwapStkAltDialog;
