import CssBaseline from '@mui/material/CssBaseline';
import Layout from 'components/layout';
import PageSkeleton from 'components/PageSkeleton';
import { useStakingInfo } from 'contexts/StakingInfoContext';
import FAQ from 'pages/FAQ';
import Disclaimer from 'pages/Legal/Disclaimer';
import PrivacyPolicy from 'pages/Legal/PrivacyPolicy';
import Portfolio from 'pages/Portfolio';
import Rewards from 'pages/Rewards';
import Stake from 'pages/Stake';
import VaultSelection from 'pages/VaultSelection';
import StakingPoolSelect from 'pages/VaultSelection/StakingPoolSelect';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { globalStyles } from 'theme/globalStyle';

export enum paths {
  Home = '/',
  Vaults = '/vaults',
  StakingPools = '/vaults/:vaultId/staking-pools',
  Stake = '/stake',
  Restake = '/restake',
  Portfolio = '/portfolio',
  Rewards = '/rewards',
  Disclaimer = '/legal/disclaimer',
  PrivacyPolicy = '/legal/privacy-policy',
  FAQ = '/docs/faq',
}

const App: React.FC = () => {
  const { isAllVaultInfoFetched } = useStakingInfo();
  const location = useLocation();
  const isImmediatelyLoadable =
    location?.pathname?.includes('docs') || location?.pathname?.includes('legal');

  return (
    <>
      <CssBaseline />
      {globalStyles}
      <Layout>
        {!isAllVaultInfoFetched && !isImmediatelyLoadable ? (
          <PageSkeleton />
        ) : (
          <Routes>
            <Route
              element={<Stake vaults={[window.appConfig?.vaults?.mach_alpha]} />}
              path={paths.Stake}
            />
            <Route
              element={
                <Stake
                  vaults={Object.values(window.appConfig?.vaults || {})?.filter(
                    cur => cur.id !== 'mach_alpha'
                  )}
                />
              }
              path={paths.Restake}
            />
            <Route element={<Portfolio />} path={paths.Portfolio} />
            <Route element={<Rewards />} path={paths.Rewards} />
            <Route element={<StakingPoolSelect />} path={paths.StakingPools} />
            <Route element={<VaultSelection />} path={paths.Vaults} />
            <Route element={<Disclaimer />} path={paths.Disclaimer} />
            <Route element={<PrivacyPolicy />} path={paths.PrivacyPolicy} />
            <Route element={<FAQ />} path={paths.FAQ} />
            <Route element={<Navigate to={paths.Stake} />} path="*" />
          </Routes>
        )}
      </Layout>
    </>
  );
};

export default App;
